<template>
  <div>
    <v-container>
      <v-row dense>
        <v-col cols="12" sm="4">
          <span class="caption font-weight-bold">Forma pagamento</span>
          <div>
            <px-forma-pagamento v-if="cotacao.formaPagamento"
                                :forma-pagamento="cotacao.formaPagamento"
                                :prazo-pagamento="cotacao.prazoPagamento"/>
            <span v-else>Não informado</span>
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <span class="caption font-weight-bold">Tipo de frete</span>
          <div>
            {{ cotacao.tipoFrete | naoInformado }}
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <span class="caption font-weight-bold">Entregar até</span>
          <div>
            {{ cotacao.prazoEntrega | date | naoInformado }}
          </div>
        </v-col>
      </v-row>
      <v-row dense class="mt-3">
        <v-col cols="12" sm="4">
          <span class="caption font-weight-bold">Valido até</span>
          <div>
            {{ cotacao.validade | date | naoInformado }}
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <span class="caption font-weight-bold">Observação</span>
          <div>
            <div class="large-text">
              {{ cotacao.observacao | naoInformado }}
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="3">
          <span class="caption font-weight-bold">Finalidade da Compra</span>
          <div v-if="cotacao.finalidadeCompra">
            <px-finalidade-compra-value
              :finalidade-compra="cotacao.finalidadeCompra"/>
          </div>
          <div v-else>
            Não Informado
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-3">
        <v-col cols="12">
          <px-resposta-card-cotacao-detalhes-produtos :produtos="cotacao.produtos"/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import RespostaCardCotacaoDetalhesProdutos from './RespostaCardCotacaoDetalhesProdutos.vue';

export default {
  props: {
    cotacao: Object,
  },
  components: {
    pxRespostaCardCotacaoDetalhesProdutos: RespostaCardCotacaoDetalhesProdutos,
  },
};
</script>
