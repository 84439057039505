import store from '@/store';

const AUTHORIZATION_HEADER = 'Authorization';
const REQUEST_ID_HEADER = 'request-id';

const authInterceptor = (config) => {
  /* eslint-disable no-param-reassign */
  const { tokenHeader, requestIdHeader, tokenHeaderFinalizacao } = store.getters;

  if (!config.headers[AUTHORIZATION_HEADER] && tokenHeader) {
    config.headers[AUTHORIZATION_HEADER] = tokenHeader;
  }

  if (!config.headers[AUTHORIZATION_HEADER] && tokenHeaderFinalizacao) {
    config.headers[AUTHORIZATION_HEADER] = tokenHeaderFinalizacao;
  }

  if (!config.headers[REQUEST_ID_HEADER] && requestIdHeader) {
    config.headers[REQUEST_ID_HEADER] = requestIdHeader;
  }

  return config;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  authInterceptor,
};
