<template>
  <div>
    <v-card class="rounded-card">
      <v-card-text>
        <div class="text--primary">
          <v-row dense>
            <v-col cols="12" sm="4">
              <span class="caption font-weight-bold">Tipo de frete</span>
              <div>
                {{ solicitacao.respostaCotacao.frete.tipoFrete | naoInformado }}
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="caption font-weight-bold">Prazo de embarque</span>
              <div>
                {{ solicitacao.respostaCotacao.frete.prazoEntrega
                | pluralize(['dia útil', 'dias úteis']) }}
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="caption font-weight-bold">Válido até</span>
              <div>
                {{ solicitacao.respostaCotacao.validade | date | naoInformado }}
              </div>
            </v-col>
          </v-row>
          <v-row dense class="mt-3">
            <v-col cols="12" sm="4">
              <span class="caption font-weight-bold">Forma de pagamento</span>
              <div>
                <px-forma-pagamento :forma-pagamento="solicitacao.respostaCotacao.formaPagamento"
                                    :prazo-pagamento="solicitacao.respostaCotacao.prazoPagamento"/>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="caption font-weight-bold">Observação</span>
              <div class="large-text">
                {{ solicitacao.respostaCotacao.observacao | naoInformado }}
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="caption font-weight-bold">Observação do frete</span>
              <div class="large-text">
                {{ solicitacao.respostaCotacao.frete.observacaoFrete | naoInformado }}
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    solicitacao: Object,
  },
};
</script>
