import axios from 'axios';
import { EventTypes } from 'px-business-components';
import EventService from '@/events';

export default {
  data() {
    return {
      eventsResource: EventTypes.Resource,
    };
  },
  methods: {
    createEventAccess(recurso, additionalData, isExternal) {
      EventService.createAcesso(axios, recurso, additionalData, isExternal);
    },
  },
};
