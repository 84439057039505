import {
  CampoNegociacao,
  SituacaoRespostaCotacaoProduto,
  SituacoesCotacao,
  TipoNegociacao,
} from 'px-business-components';
import { CasaDecimalUtils } from 'px-components';
import {
  cloneDeep, differenceWith, isArray, isEmpty, isEqual, isNumber,
} from 'lodash';

function buildProdutos(solicitacao) {
  const produtos = solicitacao.respostaCotacao.produtos.filter(produto => produto.situacao
    === SituacaoRespostaCotacaoProduto.keys.RESPONDIDA.key
    && SituacoesCotacao.ABERTAS.includes(produto.cotacaoProduto.situacao));
  return produtos.map((produto) => {
    const prod = cloneDeep(produto);
    return {
      id: prod.id,
      cotacaoProduto: {
        id: prod.cotacaoProduto.id,
        produto: prod.cotacaoProduto.produto,
        situacao: prod.cotacaoProduto.situacao,
        especificacaoProduto: prod.cotacaoProduto.especificacaoProduto,
      },
      precoUnitario: prod.precoUnitario.toFixed(prod.cotacaoProduto.produto.casasDecimais
          || CasaDecimalUtils.DEFAULT_CASAS_DECIMAIS),
      situacao: prod.situacao,
    };
  });
}

function buildNegociacao(solicitacao) {
  return {
    novaFormaPagemento: solicitacao.respostaCotacao.formaPagamento,
    novoPrazoPagamento: cloneDeep(solicitacao.respostaCotacao.prazoPagamento),
    novoTipoFrete: solicitacao.respostaCotacao.frete.tipoFrete,
    novoPrazoEntrega: solicitacao.respostaCotacao.frete.prazoEntrega,
    novoTotalTransporte: solicitacao.respostaCotacao.frete.totalTransporte
      ? solicitacao.respostaCotacao.frete.totalTransporte.toFixed(2) : 0,
    produtos: buildProdutos(solicitacao),
  };
}

function getNumberToCompare(numb, precision) {
  const defaultPrecision = precision || CasaDecimalUtils.DEFAULT_CASAS_DECIMAIS;
  if (isNumber(numb)) {
    return numb.toFixed(defaultPrecision);
  }
  return numb;
}

function isEqualFields(initial, negociacao) {
  const booleanMaps = Object.keys(initial).map((key) => {
    const initialField = initial[key];
    if (isArray(initialField)) {
      return true;
    }
    if (isNumber(initialField) || isNumber(negociacao[key])) {
      return isEqual(getNumberToCompare(initialField), getNumberToCompare(negociacao[key]));
    }
    return isEqual(initialField, negociacao[key]);
  });
  return !booleanMaps.includes(false);
}

function getDiffProdutos(initial, other) {
  return differenceWith(initial, other, (first, sec) => first.id === sec.id
    && getNumberToCompare(first.precoUnitario, first.cotacaoProduto.produto.casasDecimais)
      === getNumberToCompare(sec.precoUnitario, sec.cotacaoProduto.produto.casasDecimais));
}

function isEqualArray(initial, other) {
  const diff = getDiffProdutos(initial, other);
  return isEmpty(diff);
}

function isEqualNegociacao(initial, negociacao) {
  return isEqualFields(initial, negociacao)
    && isEqualArray(initial.produtos, negociacao.produtos);
}

function getChangedFields(initial, negociacao) {
  return Object.keys(initial).filter((key) => {
    const initialField = initial[key];
    if (isArray(initialField)) {
      return false;
    }
    if (isNumber(initialField) || isNumber(negociacao[key])) {
      return !isEqual(getNumberToCompare(initialField), getNumberToCompare(negociacao[key]));
    }

    return !isEqual(initialField, negociacao[key]);
  });
}

function getFromChangedFields(fields, negociacao) {
  const negociacaoGeral = {};
  const camposNegociacao = [];
  const campos = CampoNegociacao.values.filter(value => fields.includes(value.newField));
  campos.forEach((campo) => {
    negociacaoGeral[campo.newField] = negociacao[campo.newField];
    camposNegociacao.push(campo.key);
  });
  negociacaoGeral.campos = camposNegociacao;
  return negociacaoGeral;
}

function buildFromNegociacao(negociacao, initial, message, arquivos, resposta) {
  const registro = {
    resposta: {
      id: resposta.id,
    },
    tipo: TipoNegociacao.keys.RESPOSTA.key,
    message,
    arquivos,
  };

  const changedFields = getChangedFields(negociacao, initial);
  registro.negociacao = getFromChangedFields(changedFields, negociacao);

  registro.produtos = getDiffProdutos(negociacao.produtos, initial.produtos)
    .map(produto => ({
      respostaProduto: {
        id: produto.id,
      },
      negociacao: {
        campos: [CampoNegociacao.keys.PRECO_UNITARIO.key],
        novoPrecoUnitario: produto.precoUnitario,
      },
    }));

  return registro;
}

export {
  buildProdutos,
  buildNegociacao,
  isEqualNegociacao,
  buildFromNegociacao,
};
