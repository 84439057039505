<template>
  <div>
    <v-card class="mt-3 rounded-card">
      <v-card-text>
        <v-data-table
          show-expand
          hide-default-footer
          disable-pagination
          :headers="headers"
          :items="solicitacao.respostaCotacao.produtos"
          item-key="id"
          class="elevation-0"
        >
          <template v-slot:item.descricao="{ item }">
            <px-span-tooltip v-if="checkCodigo(item.cotacaoProduto.produto)"
                             top
                             :text="labelCodigo(item.cotacaoProduto.produto)"
                             tooltip="Código do item"
                             />
            {{ item.cotacaoProduto.produto.descricao }}
          </template>
          <template v-slot:item.quantidade="{ item }">
          <span>
            {{ item.cotacaoProduto.quantidade
            | number(getCurrencyFormat(item.cotacaoProduto.produto.casasDecimais)) }}&nbsp;
            {{ item.cotacaoProduto.unidadeMedida.descricao }}
          </span>
          </template>
          <template v-slot:item.precoUnitario="{ item }">
            <span v-if="isRespondida(item)">
              {{ item.precoUnitario
              | currency('R$', item.cotacaoProduto.produto.casasDecimais) }}
            </span>
          </template>
          <template v-slot:item.icms="{ item }">
            <px-imposto v-if="isRespondida(item)"
            :imposto="item.impostos"
            tipo="ICMS"
            :scale="item.cotacaoProduto.produto.casasDecimais"/>
          </template>
          <template v-slot:item.ipi="{ item }">
            <px-imposto v-if="isRespondida(item)"
            :imposto="item.impostos"
            tipo="IPI"
            :scale="item.cotacaoProduto.produto.casasDecimais"/>
          </template>
          <template v-slot:item.total="{ item }">
            <span>
              {{ item.total | currency('R$', item.cotacaoProduto.produto.casasDecimais) }}
            </span>
            <px-situacao-cotacao-produto :situacao="item.situacao"/>
          </template>
          <template v-slot:footer>
            <v-row dense class="text-right text--primary mt-3">
              <v-col cols="12" sm="6" class="body-1">
                <span class="font-weight-bold">
                  FRETE: {{ solicitacao.respostaCotacao.frete.totalTransporte | currency }}
                </span>
                    </v-col>
                    <v-col cols="12" sm="6" class="body-1">
                <span class="orange--text text--darken-4 font-weight-bold">
                  TOTAL: {{ solicitacao.respostaCotacao.total
                    | currency('R$', casaDecimal) }}
                </span>
              </v-col>
            </v-row>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="grey lighten-4 expanded-item">
              <v-row class="mt-3">
                <v-col cols="12" sm="3">
                  <span class="caption font-weight-bold">Código referência do fornecedor</span>
                  <div class="large-text">
                    {{ item.cotacaoProduto.produto.codigoFornecedor | naoInformado }}
                  </div>
                </v-col>
                <v-col cols="12" sm="3">
                  <span class="caption font-weight-bold">Especificação do produto</span>
                  <div class="large-text">
                    {{ item.especificacaoProduto | naoInformado }}
                  </div>
                </v-col>
                <v-col cols="12" sm="3">
                  <span class="caption font-weight-bold">Observações externas do produto</span>
                  <div class="large-text">
                    {{ observacaoExterna(item.cotacaoProduto) | naoInformado }}
                  </div>
                </v-col>
                <v-col cols="12" sm="3">
                  <span class="caption font-weight-bold">Arquivos</span>
                  <div v-if="item.arquivos.length">
                    <px-anexo-chip :files="item.arquivos"
                                   :download-base-path="downloadBasePath"/>
                  </div>
                  <div v-else>
                    Nenhum anexo informado
                  </div>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { SituacaoRespostaCotacaoProduto } from 'px-business-components';
import { CasaDecimalUtils } from 'px-components';

export default {
  props: {
    solicitacao: Object,
    solicitacaoId: [String, Number],
  },
  data() {
    return {
      headers: [
        {
          text: 'Produto',
          sortable: false,
          value: 'descricao',
        },
        {
          text: 'Quantidade',
          value: 'quantidade',
          sortable: false,
        },
        {
          text: 'Preço unitário',
          value: 'precoUnitario',
        },
        {
          text: 'ICMS',
          value: 'icms',
          sortable: false,
        },
        {
          text: 'IPI',
          value: 'ipi',
          sortable: false,
        },
        {
          text: 'Total',
          value: 'total',
        },
        {
          text: '',
          value: 'data-table-expand',
        },
      ],
    };
  },
  computed: {
    isRespondida() {
      return item => item.situacao
          === SituacaoRespostaCotacaoProduto.keys.RESPONDIDA.key;
    },
    downloadBasePath() {
      return `external/resposta/${this.solicitacaoId}/file`;
    },
    labelCodigo() {
      return item => `${item.codigo} - `;
    },
    checkCodigo() {
      return item => item.codigo;
    },
    getCurrencyFormat() {
      return item => CasaDecimalUtils.getCurrencyFormat(item);
    },
    casaDecimal() {
      const mappedProdutos = this.solicitacao.respostaCotacao.produtos.map(
        produto => produto.cotacaoProduto.produto,
      );
      return CasaDecimalUtils.getCasaDecimal(mappedProdutos);
    },
    observacaoExterna() {
      return produto => produto.observacaoExterna ?? produto.produto.observacaoExterna;
    },
  },
};
</script>

<style scoped>
  .expanded-item {
    box-shadow: inset 0 4px 4px -5px rgba(50, 50, 50, 0.75),
    inset 0 -4px 4px -5px rgba(50, 50, 50, 0.75);
  }
</style>
