<template>
  <div>
    <v-row dense>
      <v-col cols="12" class="caption">
        <v-icon>mdi-domain</v-icon>
        {{ cotacao.comprador.pessoa.nome }} ({{ cotacao.comprador.pessoa.cnpj | cnpj }})
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" class="caption">
        <v-icon>mdi-map-marker</v-icon>
        {{ cotacao.comprador.endereco | endereco }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    cotacao: Object,
  },
};
</script>
