<template>
  <v-data-table
    hide-default-footer
    disable-pagination
    :headers="headers"
    :items="resposta.produtos"
    item-key="id"
    class="elevation-0"
  >
  <template v-slot:item.cotacaoProduto.produto.descricao="{ item }">
      <px-span-tooltip v-if="checkCodigo(item.cotacaoProduto.produto)"
                      top
                      :text="labelCodigo(item.cotacaoProduto.produto)"
                      tooltip="Código do item"/>
            {{ item.cotacaoProduto.produto.descricao }}
    </template>
    <template v-slot:item.quantidade="{ item }">
          <span>
            {{ item.cotacaoProduto.quantidade
            | number(getCurrencyFormat(item.cotacaoProduto.produto.casasDecimais)) }}&nbsp;
            {{ item.cotacaoProduto.unidadeMedida.descricao }}
          </span>
    </template>
    <template v-slot:item.precoUnitario="{ item }">
      <span v-if="isRespondida(item)">
        {{ item.precoUnitario
          | currency('R$',  item.cotacaoProduto.produto.casasDecimais) }}
      </span>
    </template>
    <template v-slot:item.icms="{ item }">
      <px-imposto v-if="isRespondida(item)"
      :imposto="item.impostos"
      tipo="ICMS"
      :scale="item.cotacaoProduto.produto.casasDecimais"/>
    </template>
    <template v-slot:item.ipi="{ item }">
      <px-imposto v-if="isRespondida(item)"
      :imposto="item.impostos"
      tipo="IPI"
      :scale="item.cotacaoProduto.produto.casasDecimais"/>
    </template>
    <template v-slot:item.total="{ item }">
      <span v-if="isRespondida(item)">
        {{ item.total | currency('R$', item.cotacaoProduto.produto.casasDecimais) }}
      </span>
      <px-situacao-cotacao-produto :situacao="item.situacao"/>
    </template>
    <template v-slot:footer>
      <v-row dense class="text-right text--primary">
        <v-col cols="12" sm="6" class="body-1">
          <span class="font-weight-bold">
            FRETE: {{ resposta.frete.totalTransporte | currency }}
          </span>
        </v-col>
        <v-col cols="12" sm="6" class="body-1">
          <span class="orange--text text--darken-4 font-weight-bold">
            TOTAL: {{ total | currency('R$', casaDecimal) }}
          </span>
        </v-col>
      </v-row>
    </template>
  </v-data-table>
</template>

<script>
import currency from 'currency.js';
import {
  SituacaoRespostaCotacaoProduto,
  AplicacaoImposto,
} from 'px-business-components';
import { CasaDecimalUtils } from 'px-components';
import { DEFAULT_PRECISION } from './resposta-utils';

export default {
  props: {
    resposta: Object,
  },
  data() {
    return {
      casaDecimal: null,
      headers: [
        {
          text: 'Produto',
          sortable: false,
          value: 'cotacaoProduto.produto.descricao',
        },
        {
          text: 'Quantidade',
          value: 'quantidade',
          sortable: false,
        },
        {
          text: 'Preço unitário',
          value: 'precoUnitario',
        },
        {
          text: 'ICMS',
          value: 'icms',
          sortable: false,
        },
        {
          text: 'IPI',
          value: 'ipi',
          sortable: false,
        },
        {
          text: 'Total',
          value: 'total',
        },
      ],
    };
  },
  computed: {
    isRespondida() {
      return item => item.situacao === SituacaoRespostaCotacaoProduto.keys.RESPONDIDA.key;
    },
    isIsento() {
      return tipoAplicacao => tipoAplicacao
        === AplicacaoImposto.keys.ISENTO.key;
    },
    isIncluso() {
      return tipoAplicacao => tipoAplicacao
        === AplicacaoImposto.keys.INCLUSO.key;
    },
    total() {
      const produtos = this.resposta.produtos
        .filter(produto => this.isRespondida(produto))
        .map(produto => produto.total)
        .reduce((a, b) => a + b);
      return currency(produtos, { precision: DEFAULT_PRECISION })
        .add(currency(this.resposta.frete.totalTransporte))
        .value;
    },
    totalProdutos() {
      return this.resposta.produtos
        .filter(produto => this.isRespondida(produto))
        .map(a => a.totalProdutos)
        .reduce((a, b) => a + b);
    },
    checkCodigo() {
      return item => item.codigo;
    },
    labelCodigo() {
      return item => `${item.codigo} -`;
    },
    getCurrencyFormat() {
      return item => CasaDecimalUtils.getCurrencyFormat(item);
    },
  },
  methods: {
    calculateTotalProduto(produto) {
      return currency(produto.cotacaoProduto.quantidade, { precision: DEFAULT_PRECISION })
        .multiply(currency(produto.precoUnitario, { precision: DEFAULT_PRECISION })).value;
    },
    calculateIcms(produto) {
      if (this.isIsento(produto.impostos.tipoAplicacaoIcms)) {
        return 0;
      }
      const { totalProdutos } = produto;
      const icms = currency(produto.impostos.icms, { precision: DEFAULT_PRECISION })
        .multiply(100, { precision: DEFAULT_PRECISION });
      return icms.multiply(
        currency(totalProdutos, { precision: DEFAULT_PRECISION }), { precision: DEFAULT_PRECISION },
      )
        .divide(100, { precision: DEFAULT_PRECISION }).value;
    },
    calculateIpi(produto) {
      if (this.isIsento(produto.impostos.tipoAplicacaoIpi)) {
        return 0;
      }
      const ipi = currency(produto.impostos.ipi, { precision: DEFAULT_PRECISION }).multiply(100);
      const valorBase = currency(produto.totalProdutos, { precision: DEFAULT_PRECISION })
        .add(currency(produto.frete, { precision: DEFAULT_PRECISION })).value;

      if (this.isIncluso(produto.impostos.tipoAplicacaoIpi)) {
        return valorBase.multiply(ipi, { precision: DEFAULT_PRECISION })
          .divide(ipi.add(100, { precision: DEFAULT_PRECISION })).value;
      }
      return ipi.multiply(valorBase, { precision: DEFAULT_PRECISION })
        .divide(100, { precision: DEFAULT_PRECISION }).value;
    },
    getIpiACalcular(produto) {
      if (produto.impostos.tipoAplicacaoIpi === AplicacaoImposto.keys.CALCULAR.key) {
        return produto.impostos.valorIpi;
      }
      return 0;
    },
    calculateTotal(produto) {
      return currency(produto.totalProdutos, { precision: DEFAULT_PRECISION })
        .add(currency(this.getIpiACalcular(produto), { precision: DEFAULT_PRECISION }))
        .value;
    },
    calculatePercentualRateio(produto) {
      if (this.resposta.frete.totalTransporte === 0) {
        return 0;
      }
      return currency(produto.totalProdutos, { precision: DEFAULT_PRECISION })
        .multiply(100)
        .divide(currency(this.totalProdutos, { precision: DEFAULT_PRECISION }))
        .value;
    },
    calculateFreteItem(produto) {
      if (this.resposta.frete.totalTransporte === 0) {
        return 0;
      }
      return currency(produto.percentualRateio, { precision: DEFAULT_PRECISION })
        .multiply(this.resposta.frete.totalTransporte)
        .divide(100)
        .value;
    },
    calculateTotalProdutos() {
      this.resposta.produtos.forEach((produto) => { /* eslint-disable no-param-reassign */
        if (!this.isRespondida(produto)) {
          return;
        }
        produto.totalProdutos = this.calculateTotalProduto(produto);
        produto.impostos.valorIcms = this.calculateIcms(produto);
      });
    },
    calculateRateioIpiTotal() {
      this.resposta.produtos.forEach((produto) => { /* eslint-disable no-param-reassign */
        if (!this.isRespondida(produto)) {
          return;
        }

        produto.percentualRateio = this.calculatePercentualRateio(produto);
        produto.frete = this.calculateFreteItem(produto);
        produto.impostos.valorIpi = this.calculateIpi(produto);
        produto.total = this.calculateTotal(produto);
      });
    },
    defineCasaDecimal() {
      const produtos = this.resposta.produtos.map(produto => produto.cotacaoProduto.produto);
      this.casaDecimal = CasaDecimalUtils.getCasaDecimal(produtos);
    },
  },
  created() {
    // primeiro faz o calcula básico de total dos produtos
    this.calculateTotalProdutos();
    // depois do calculo básico, faz o calculo do rateio de frete, IPI e total
    this.calculateRateioIpiTotal();
    // define o numero de casas decimais pra exibição dos valores
    this.defineCasaDecimal();
  },
};
</script>
