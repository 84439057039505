<template>
  <div>
    <v-expansion-panels focusable>
      <v-expansion-panel class="rounded-card">
        <v-expansion-panel-header>
          <px-reposta-card-cotacao-header :cotacao="cotacao"/>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <px-resposta-card-cotacao-detalhes :cotacao="cotacao"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import RepostaCardCotacaoHeader from '@/modules/resposta/RepostaCardCotacaoHeader.vue';
import RespostaCardCotacaoDetalhes from '@/modules/resposta/RespostaCardCotacaoDetalhes.vue';

export default {
  props: {
    cotacao: Object,
  },
  components: {
    pxRepostaCardCotacaoHeader: RepostaCardCotacaoHeader,
    pxRespostaCardCotacaoDetalhes: RespostaCardCotacaoDetalhes,
  },
};
</script>
