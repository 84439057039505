<template>
  <div>
    <div class="px-5 mt-3">
      <v-row>
        <v-col cols="12" sm="4">
          <v-btn class="mb-4 mr-1" icon x-large color="primary" link @click="onClickBack">
            <v-icon>
              mdi-arrow-left
            </v-icon>
          </v-btn>
          <span class="display-1 font-weight-bold">Minha resposta</span>
        </v-col>
        <v-col cols="12" sm="8" v-if="solicitacao" class="text-right body-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                {{ solicitacao.fornecedor.pessoa.nome }}
                ({{ solicitacao.fornecedor.pessoa.cnpj | cnpj}})
              </span>
            </template>
            <span>Empresa fornecedora</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </div>
    <div v-if="loading" class="text-center">
      <px-circular-loading/>
    </div>
    <div v-else>
      <px-minha-resposta-dados-basicos :solicitacao="solicitacao"/>
      <px-minha-resposta-produtos-table :solicitacao="solicitacao" :solicitacao-id="cotacaoId"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SolicitacaoService from '@/modules/cotacao/solicitacao-service';
import MinhaRespostaDadosBasicos from './MinhaRespostaDadosBasicos.vue';
import MinhaRespostaProdutosTable from './MinhaRespostaProdutosTable.vue';

export default {
  data() {
    return {
      loading: false,
      solicitacao: null,
    };
  },
  computed: {
    ...mapGetters({
      cotacaoId: 'cotacaoId',
    }),
  },
  methods: {
    getSolicitacao() {
      this.loading = true;
      return SolicitacaoService.getSolicitacao(this.cotacaoId)
        .then((response) => {
          this.solicitacao = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onClickBack() {
      this.$router.go(-1);
    },
  },
  created() {
    this.getSolicitacao();
  },
  components: {
    pxMinhaRespostaDadosBasicos: MinhaRespostaDadosBasicos,
    pxMinhaRespostaProdutosTable: MinhaRespostaProdutosTable,
  },
};
</script>
