<template>
  <div>
    <v-alert color="primary" class="rounded-card" outlined dismissible>
      <b class="error--text">NÃO COBRAMOS COMISSÕES EM COTAÇÕES!</b><br>
      Você sabia que temos uma plataforma <b>gratuita</b> e
      <b>exclusiva</b> para <b>fornecedores</b>?
      Nela você pode acessar seu histórico, ver os feedbacks e avaliações da sua empresa, gerenciar
      vendedores e atender ainda melhor o seu cliente.
      <v-btn small link
             href="https://fornecedor.proxpect.com.br/nova-conta"
             target="_blank"
             color="primary"
             class="ml-2 normal-btn-text rounded-tag-small">
        Quero me cadastrar agora
        <v-icon small class="ml-1">mdi-open-in-new</v-icon>
      </v-btn>
    </v-alert>
  </div>
</template>

<script>
export default {
};
</script>
