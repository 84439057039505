<template>
  <div>
    <v-row dense v-if="negociacao.produtos.length">
      <v-col cols="12" class="body-1 font-weight-bold">
        Produtos
      </v-col>
    </v-row>
    <v-card
      v-if="negociacao.produtos.length"
      outlined
      class="rounded-card"
    >
      <v-list>
        <template v-for="(produto, index) in negociacao.produtos">
          <v-list-item :key="produto.id">
            <v-list-item-content>
              <v-list-item-title>
                <px-detalhes-produto
                  small
                  v-if="produto.cotacaoProduto"
                  :produto="produto.cotacaoProduto"
                  show-obs-externa/>
                  <px-span-tooltip v-if="checkCodigo(produto.cotacaoProduto.produto)"
                    top
                    :text="labelCodigo(produto.cotacaoProduto.produto)" tooltip="Código do item"/>
                  <px-span-tooltip top
                                  :text="labelDescricao(produto)"
                                  :tooltip="produto.cotacaoProduto.produto.descricao"/>
              </v-list-item-title>
              <v-list-item-subtittle class="grey--text">
                <px-span-tooltip v-if="codigoFornecedor(produto)"
                                 bottom
                                 :text="codigoFornecedor(produto)"
                                 tooltip="Código de referência do fornecedor"/>
              </v-list-item-subtittle>
            </v-list-item-content>
            <v-list-item-content>
              <px-input-money
                label="Preço unitário"
                class="required rounded-tag-small"
                v-model="produto.precoUnitario"
                :precision="produto.cotacaoProduto.produto.casasDecimais"
                :error="$v.negociacao.produtos.$each[index].precoUnitario.$error"
                @blur="$v.negociacao.produtos.$each[index].precoUnitario.$touch()"/>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    negociacao: Object,
  },
  data() {
    return {
    };
  },
  methods: {
    validate() {
      this.$v.$touch();
      return this.$v.$invalid;
    },
  },
  computed: {
    labelDescricao() {
      return produto => Vue.filter('truncate')(produto.cotacaoProduto.produto.descricao, 10);
    },
    labelCodigo() {
      return item => `${item.codigo} -`;
    },
    checkCodigo() {
      return item => item.codigo;
    },
    codigoFornecedor() {
      return produto => produto.cotacaoProduto.produtoFornecedor;
    },
  },
  validations: {
    negociacao: {
      produtos: {
        $each: {
          precoUnitario: {
            required,
            minValue(value) {
              return value > 0;
            },
          },
        },
      },
    },
  },
};
</script>
