<template>
  <div>
    <v-row dense>
      <v-col cols="12" class="font-weight-bold">
        {{title}}
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="6"
             v-for="campo in negociacao.negociacao.campos"
             :key="campo">
        <px-negociacao-diff-campo
          :value-changed-class="valueClass"
          :campo="campo"
          :negociacao="negociacao.negociacao"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { TipoNegociacao } from 'px-business-components';

export default {
  props: {
    title: {
      type: String,
      default: 'Alterações solicitadas:',
    },
    negociacao: Object,
  },
  computed: {
    valueClass() {
      if (this.negociacao.tipo === TipoNegociacao.keys.RESPOSTA.key) {
        return 'green--text font-weight-bold';
      }
      return 'orange--text font-weight-bold';
    },
  },
};
</script>
