<template>
  <v-container class="mb-4">
    <v-row class="pa-3">
      <v-icon class="pr-2">mdi-currency-usd</v-icon>
      <px-forma-pagamento :forma-pagamento="resposta.formaPagamento"
                          :prazo-pagamento="resposta.prazoPagamento"/>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-icon class="pr-2">mdi-calendar</v-icon>
        Proposta válida até {{ resposta.validade | date }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-icon class="pr-2">mdi-truck</v-icon>
        Frete {{ resposta.frete.tipoFrete }}
        ({{ resposta.frete.prazoEntrega | pluralize(['dia útil', 'dias úteis'])}})
      </v-col>
    </v-row>
    <div>
      <px-resposta-cadastro-confirmacao-table :resposta="resposta"/>
    </div>
  </v-container>
</template>

<script>
import RespostaCadastroConfirmacaoTable from './RespostaCadastroConfirmacaoTable.vue';

export default {
  props: {
    resposta: Object,
  },
  components: {
    pxRespostaCadastroConfirmacaoTable: RespostaCadastroConfirmacaoTable,
  },
};
</script>
