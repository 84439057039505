<template>
  <v-card class="rounded-card mt-3">
    <v-card-title>
      <span class="font-weight-bold">
        Mensagens personalizadas pelo comprador
      </span>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :items="finalizacao.mensagensPersonalizadas"
        :headers="headers"
        :server-items-length="finalizacao.mensagensPersonalizadas.length"
        no-data-text="Nenhuma mensagem encontrada"
        loading-text="Carregando mensagens..."
        hide-default-footer
      >
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    finalizacao: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Código',
          value: 'codigoInterno',
          sortable: false,
        },
        {
          text: 'Mensagem',
          value: 'mensagem',
          sortable: false,
        },
      ],
    };
  },
};
</script>
