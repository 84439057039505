<template>
  <px-lateral-dialog v-model="dialog" width="800">
    <template v-slot:activator="{ on }">
      <v-btn color="primary"
             class="normal-btn-text rounded-tag-small"
             v-on="on">
        Editar/Aprovar
      </v-btn>
    </template>
    <template v-slot:title>
      Aprovar solicitação
    </template>
    <template>
      <v-alert type="warning" class="rounded-card" outlined>
        Confirme as informações abaixo para finalizar sua venda. Após sua confirmação,
        <b>aguarde</b>
        a confirmação e a ordem de compra do comprador para enviar a mercadoria.
      </v-alert>
      <v-row>
        <v-col cols="12" sm="6">
          <px-select-enum label="Forma de pagamento"
                          class="required rounded-tag-small"
                          :items="formasPagamentos"
                          v-model="finalizacao.formaPagamento"
                          :error="$v.finalizacao.formaPagamento.$error"
                          @blur="$v.finalizacao.formaPagamento.$touch()"
                          @change="onChangeFormaPagamento"/>
        </v-col>
        <v-col cols="12" sm="6">
          <px-autocomplete-prazo-pagamento
            :class="{'required': !isAVista, 'rounded-tag-small': true}"
            :disabled="isAVista"
            v-model="finalizacao.prazoPagamento"
            :error="$v.finalizacao.prazoPagamento.$error"
            @blur="$v.finalizacao.prazoPagamento.$touch()"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4">
          <px-select-enum class="required rounded-tag-small"
                          label="Tipo de frete"
                          :items="tiposFrete"
                          v-model="finalizacao.frete.tipoFrete"
                          :error="$v.finalizacao.frete.tipoFrete.$error"
                          @blur="$v.finalizacao.frete.tipoFrete.$touch()"
                          @change="onChangeTipoFrete"/>
        </v-col>
        <v-col cols="12" sm="4">
          <px-input-number label="Prazo (Dias úteis)"
                           class="required rounded-tag-small"
                           v-model="finalizacao.frete.prazoEntrega"
                           :error="$v.finalizacao.frete.prazoEntrega.$error"
                           @blur="$v.finalizacao.frete.prazoEntrega.$touch()"/>
        </v-col>
        <v-col cols="12" sm="4">
          <px-input-money
            label="Valor do frete"
            class="required rounded-tag-small"
            ref="totalTransporte"
            :disabled="isFob"
            v-model="finalizacao.frete.totalTransporte"
            :error="$v.finalizacao.frete.totalTransporte.$error"
            @blur="$v.finalizacao.frete.totalTransporte.$touch()"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea
            outlined
            auto-grow
            class="required rounded-tag-small"
            label="Mensagem para o comprador"
            counter="1500"
            rows="1"
            v-model="finalizacao.mensagemFornecedor"
            :error="$v.finalizacao.mensagemFornecedor.$error"
            @blur="$v.finalizacao.mensagemFornecedor.$touch()"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-spacer/>
      <v-btn text class="normal-btn-text" @click="close">
        Cancelar
      </v-btn>
      <v-btn color="primary"
             class="normal-btn-text rounded-tag-small"
             :loading="loadingSave" @click="aprovar">
        Aprovar
      </v-btn>
    </template>
  </px-lateral-dialog>
</template>

<script>
import { FormaPagamento, TipoFrete } from 'px-business-components';
import { cloneDeep } from 'lodash';
import { required, requiredIf } from 'vuelidate/lib/validators';
import validationMixin from '@/mixins/validation-mixin';
import FinalizacaoService from './finalizacao-service';

export default {
  props: {
    compra: Object,
    compraId: String,
  },
  mixins: [validationMixin],
  data() {
    return {
      formasPagamentos: FormaPagamento.values,
      tiposFrete: TipoFrete.values,
      loadingSave: false,
      dialog: false,
      finalizacao: {
        formaPagamento: null,
        prazoPagamento: null,
        mensagemFornecedor: null,
        frete: {
          tipoFrete: null,
          prazoEntrega: null,
          totalTransporte: 0,
        },
      },
    };
  },
  computed: {
    isAVista() {
      return this.finalizacao.formaPagamento === FormaPagamento.keys.A_VISTA.key;
    },
    isFob() {
      return this.finalizacao.frete.tipoFrete === TipoFrete.keys.FOB.key;
    },
  },
  methods: {
    aprovar() {
      if (this.validateForm()) {
        return;
      }
      this.loadingSave = true;
      FinalizacaoService.aprovar(this.compraId, this.finalizacao)
        .then(() => {
          this.$emit('movimentar');
          this.$toast('Solicitação aceita com sucesso', { color: 'success' });
        })
        .finally(() => {
          this.loadingSave = false;
        });
    },
    onChangeFormaPagamento() {
      this.finalizacao.prazoPagamento = null;
    },
    onChangeTipoFrete() {
      // workaround para zerar v-money
      this.$refs.totalTransporte.$el.getElementsByTagName('input')[0].value = 0;
      this.finalizacao.frete.totalTransporte = 0;
    },
    close() {
      this.dialog = false;
    },
    buildInitialState() {
      this.finalizacao.formaPagamento = this.compra.formaPagamento;
      this.finalizacao.prazoPagamento = this.compra.prazoPagamento;
      this.finalizacao.frete = cloneDeep(this.compra.frete);
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      if (this.compra) {
        this.buildInitialState();
      }
    },
  },
  validations: {
    finalizacao: {
      prazoPagamento: {
        required: requiredIf(function () {
          return !this.isAVista;
        }),
      },
      formaPagamento: {
        required,
      },
      frete: {
        tipoFrete: {
          required,
        },
        prazoEntrega: {
          required,
        },
        totalTransporte: {
          required,
        },
      },
      mensagemFornecedor: {
        required,
      },
    },
  },
};
</script>
