<template>
  <v-dialog v-model="localValue"
            persistent
            max-width="400">
    <v-card>
      <v-card-title class="headline">Código de acesso</v-card-title>
      <v-card-text class="pa-4">
        <v-form
          ref="form"
          @submit.prevent=""
        >
          <div class="error--text mb-5"
               v-if="error">
            {{error}}
          </div>
          <v-text-field
            outlined
            hide-details
            required
            :error="$v.codigoAcesso.$error"
            @blur="$v.codigoAcesso.$touch()"
            v-model="codigoAcesso"
            label="Código de acesso"
            v-on:keyup.native.enter="validar"
          />
        </v-form>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer/>
        <v-btn color="primary"
               type="button"
               :disabled="$v.$invalid"
               :loading="loading"
               @click.native="validar">
          Acessar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import {
  SET_AUTH_INFO, SET_AUTH_INFO_FIN,
} from '@/store/auth/actions.type';
import AuthService from './auth-service';

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    cotacaoId: {
      type: String,
      required: true,
    },
    isFinalizacao: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    codigoAcesso: null,
    error: null,
    loading: false,
  }),
  computed: {
    localValue: {
      get() { return this.value; },
      set(localValue) { this.$emit('input', localValue); },
    },
  },
  validations: {
    codigoAcesso: {
      required,
    },
  },
  methods: {
    ...mapActions({
      setAuthToken: SET_AUTH_INFO,
      setAuthTokenFinalizacao: SET_AUTH_INFO_FIN,
    }),
    validar() {
      this.loading = true;
      this.getValidationMethod()(this.cotacaoId, { token: this.codigoAcesso })
        .then((response) => {
          this.localValue = false;
          if (this.isFinalizacao) {
            this.setAuthTokenFinalizacao(response.data.token);
          } else {
            this.setAuthToken(response.data.token);
          }
          this.$emit('validate');
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.error = 'Código de acesso inválido';
            return;
          }
          this.error = 'Erro ao autenticar a chave de acesso, tente novamente mais tarde';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getValidationMethod() {
      if (this.isFinalizacao) {
        return AuthService.validarFinalizacao;
      }
      return AuthService.validar;
    },
  },
};
</script>
