import {
  SET_AUTH_INFO, SET_AUTH_INFO_FIN, INITIALIZE_FROM_COOKIES_AC, INITIALIZE_FROM_COOKIES_AC_FIN,
} from './actions.type';
import {
  SET_AUTH_TOKENS,
  SET_AUTH_TOKENS_FIN,
  INITIALIZE_FROM_COOKIES,
  INITIALIZE_FROM_COOKIES_FIN,
} from './mutations.type';

export default {
  [SET_AUTH_INFO](context, data) {
    context.commit(SET_AUTH_TOKENS, { data, getters: context.getters });
  },
  [SET_AUTH_INFO_FIN](context, data) {
    context.commit(SET_AUTH_TOKENS_FIN, { data, getters: context.getters });
  },
  [INITIALIZE_FROM_COOKIES_AC](context) {
    context.commit(INITIALIZE_FROM_COOKIES, { getters: context.getters });
  },
  [INITIALIZE_FROM_COOKIES_AC_FIN](context) {
    context.commit(INITIALIZE_FROM_COOKIES_FIN, { getters: context.getters });
  },
};
