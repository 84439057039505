<template>
  <v-container fluid>
    <v-container class="app-container">
      <router-view></router-view>
    </v-container>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped>
  .app-container {
    max-width: 1280px;
    border-radius: 2px;
    background-color: white;
    min-height: 500px;
  }
</style>
