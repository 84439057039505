<template>
  <div>
    <v-container class="mb-4" fluid>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left" style="min-width: 320px">Produto</th>
            <th class="text-left" style="min-width: 120px">Qtd</th>
            <th style="min-width: 150px">Preço</th>
            <th style="min-width: 125px">ICMS</th>
            <th style="min-width: 125px">IPI</th>
            <th style="min-width: 100px;"></th>
          </tr>
          </thead>
          <tbody>
          <px-resposta-cadastro-produtos-table-row
            ref="produtoItem"
            v-for="(respostaProduto, index) in resposta.produtos"
            :key="index"
            :resposta-produto="respostaProduto"
            @replicar-preco="replicarPreco"
            @replicar-imposto="replicarImposto"/>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
    <v-btn depressed
           color="primary"
           class="rounded-tag-small normal-btn-text"
           @click="next">
      Continuar
    </v-btn>
  </div>
</template>

<script>
import validationMixin from '@/mixins/validation-mixin';
import { NumberUtils } from 'px-components';
import pxRespostaCadastroProdutosTableRow
from './RespostaCadastroProdutosTableRow.vue';

export default {
  props: {
    solicitacao: Object,
    resposta: Object,
  },
  mixins: [validationMixin],
  data() {
    return {
      refs_children: [],
    };
  },
  computed: {
    isInvalid() {
      const forms = this.refs_children.map(c => c.validateForm());
      const filesUploading = this.refs_children.map(c => c.hasFileLoading);
      if (filesUploading.find(f => f)) {
        this.$toast('Aguarde a finalização do envio do(s) arquivo(s)', { color: 'error' });
        return true;
      }
      return forms.find(form => form);
    },
  },
  methods: {
    next() {
      if (this.isInvalid) {
        return;
      }
      const hasFileLoading = this.resposta.produtos.find(p => p.$$hasFileLoading);
      if (hasFileLoading) {
        this.$toast('Aguarde o envio dos anexos e tente novamente', { color: 'warning' });
        return;
      }
      this.resposta.produtos = this.resposta.produtos.map(produto => ({
        ...produto,
        impostos: {
          ...produto.impostos,
          tipoAplicacaoIcms: produto.impostos.icms ? 'INCLUSO' : 'ISENTO',
          tipoAplicacaoIpi: produto.impostos.ipi ? 'CALCULAR' : 'ISENTO',
        },
      }));
      this.$emit('next');
    },
    replicarPreco(respostaProduto) {
      const precision = respostaProduto.cotacaoProduto.produto.casasDecimais ?? 2;
      const novoPreco = NumberUtils.fixNumberInput(respostaProduto.precoUnitario, false, precision);
      this.$refs.produtoItem.forEach((produto) => {
        // produtos recusados não possuem o input de preço unitário
        if (!produto.$refs.precoUnitario) {
          return;
        }
        // eslint-disable-next-line no-param-reassign
        produto.$refs.precoUnitario.$el.getElementsByTagName('input')[0].value = novoPreco;
      });
      this.resposta.produtos = this.resposta.produtos.map(produto => ({
        ...produto,
        precoUnitario: novoPreco,
      }));
    },
    replicarImposto(respostaProduto, tipoImposto) {
      const novoImposto = NumberUtils.fixNumberInput(respostaProduto.impostos[tipoImposto], true);
      this.$refs.produtoItem.forEach((produto) => {
        // produtos recusados não possuem o input de impostos
        if (!produto.$refs[tipoImposto]) {
          return;
        }
        // eslint-disable-next-line no-param-reassign
        produto.$refs[tipoImposto].$el.getElementsByTagName('input')[0].value = novoImposto;
      });
      this.resposta.produtos = this.resposta.produtos.map(produto => ({
        ...produto,
        impostos: {
          ...produto.impostos,
          [tipoImposto]: novoImposto,
        },
      }));
    },
  },
  created() {
    this.resposta.produtos = this.resposta.produtos.map(produto => ({
      ...produto,
      impostos: {
        ...produto.impostos,
        icms: NumberUtils.fixNumberInput(produto.cotacaoProduto.produto.icms, true) ?? 0,
        ipi: NumberUtils.fixNumberInput(produto.cotacaoProduto.produto.ipi, true) ?? 0,
      },
    }));
  },
  mounted() {
    this.refs_children = this.$refs.produtoItem;
  },
  components: {
    pxRespostaCadastroProdutosTableRow,
  },
};
</script>
