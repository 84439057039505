<template>
  <v-bottom-sheet
    persistent
    v-model="sheet"
  >
    <v-sheet
      class="text-center"
      height="360px"
    >
      <div class="pt-6 body-1">
        Você sabia que temos uma plataforma <b>gratuita</b> e
        <b>exclusiva</b> para <b>fornecedores</b>?
      </div>
      <div>
        Nela, você tem os seguintes benefícios:
      </div>
      <v-container fluid>
        <v-row justify="center">
          <v-list dense>
            <v-list-item dense>
              <v-list-item-icon>
                <v-icon>mdi-clock-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content align="start">
                <v-list-item-title>
                  Acesse todo o <b>histórico</b> de cotações, negociações e vendas
                  feitas pela Proxpect
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item dense>
              <v-list-item-icon>
                <v-icon>mdi-clipboard-list-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content align="start">
                <v-list-item-title>
                  Receba <b>feedback</b> sobre preço, prazo de pagamento e prazo de embarque
                  das cotações que você participou
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item dense>
              <v-list-item-icon>
                <v-icon>mdi-account-group-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content align="start">
                <v-list-item-title>
                  Responda cotações de diferentes <b>grupos de compra</b>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item dense>
              <v-list-item-icon>
                <v-icon>mdi-account-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content align="start">
                <v-list-item-title>
                  Adicione e gerencie seus <b>usuários</b> vendedores
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item dense>
              <v-list-item-icon>
                <v-icon>mdi-currency-usd</v-icon>
              </v-list-item-icon>
              <v-list-item-content align="start">
                <v-list-item-title>
                  Responda mais rapidamente seus clientes e tenha <b>novas oportunidades</b>
                  de negócio
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-row>
      </v-container>
      <v-btn
        link
        class="mr-2"
        href="https://fornecedor.proxpect.com.br/nova-conta"
        target="_blank"
        color="primary"
      >
        Criar minha conta gratuita
      </v-btn>
      <v-btn
        text
        color="error"
        @click="close"
      >
        Fechar
      </v-btn>
    </v-sheet>
  </v-bottom-sheet>
</template>
<script>
export default {
  data() {
    return {
      sheet: true,
    };
  },
  computed: {
    isSmall() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    close() {
      this.sheet = false;
    },
  },
  mounted() {
    if (this.isSmall) {
      this.sheet = false;
    }
  },
};
</script>
