import {
  SET_COTACAO_ID_AC,
} from './actions.type';
import { SET_COTACAO_ID } from './mutations.type';

export default {
  [SET_COTACAO_ID_AC](context, id) {
    context.commit(SET_COTACAO_ID, id);
  },
};
