<template>
  <div>
    <v-form>
      <v-container class="mb-4">
        <v-row>
          <v-col class="d-flex" cols="12" sm="4">
            <px-select-enum label="Forma de pagamento"
                            class="required rounded-tag-small"
                            :error="$v.resposta.formaPagamento.$error"
                            @blur="$v.resposta.formaPagamento.$touch()"
                            v-model="resposta.formaPagamento"
                            :items="formasPagamentos"/>
          </v-col>
          <v-col class="d-flex" cols="12" sm="4">
            <px-autocomplete-prazo-pagamento
                            :class="{'required': !isAVista, 'rounded-tag-small': true}"
                            :disabled="isAVista"
                            :error="$v.resposta.prazoPagamento.$error"
                            @blur="$v.resposta.prazoPagamento.$touch()"
                            v-model="resposta.prazoPagamento"/>
          </v-col>
          <v-col class="d-flex" cols="12" sm="4">
            <px-date-picker label="Validade da cotação"
                            input-class="required rounded-tag-small"
                            :error="$v.resposta.validade.$error"
                            @blur="$v.resposta.validade.$touch()"
                            v-model="resposta.validade"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              outlined
              v-model="resposta.observacao"
              label="Observações"
              counter="1500"
              class="rounded-tag-small"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-btn color="primary"
             class="normal-btn-text rounded-tag-small"
             @click="next">
        Continuar
      </v-btn>
      <v-btn text class="normal-btn-text" @click="previous">Voltar</v-btn>
    </v-form>
  </div>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators';
import { FormaPagamento } from 'px-business-components';
import validationMixin from '@/mixins/validation-mixin';

export default {
  props: {
    resposta: Object,
    cotacao: Object,
  },
  mixins: [validationMixin],
  data() {
    return {
      formasPagamentos: FormaPagamento.values,
    };
  },
  computed: {
    isAVista() {
      return this.resposta.formaPagamento === FormaPagamento.keys.A_VISTA.key;
    },
  },
  methods: {
    next() {
      if (this.validateForm()) {
        return;
      }
      this.$emit('next');
    },
    previous() {
      this.$emit('previous');
    },
  },
  watch: {
    'resposta.formaPagamento': function onChangeFormaPagamento() {
      if (this.isAVista) {
        this.resposta.prazoPagamento = null;
      }
    },
  },
  validations: {
    resposta: {
      prazoPagamento: {
        required: requiredIf(function () {
          return !this.isAVista;
        }),
      },
      formaPagamento: {
        required,
      },
      validade: {
        required,
      },
    },
  },
};
</script>
