<template>
  <div>
      <v-row class="mt-3" dense>
        <v-col cols="12">
          <px-select-enum label="Forma de pagamento"
                          class="required rounded-card-small"
                          v-model="negociacao.novaFormaPagemento"
                          :items="formasPagamentos"
                          :error="$v.negociacao.novaFormaPagemento.$error"
                          @blur="$v.negociacao.novaFormaPagemento.$touch()"
                          @change="onChangeFormaPagamento"/>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <px-autocomplete-prazo-pagamento label="Prazo de pagamento"
                                           :class="prazoClass"
                                           :disabled="isAvista"
                                           :error="$v.negociacao.novoPrazoPagamento.$error"
                                           @blur="$v.negociacao.novoPrazoPagamento.$touch()"
                                           v-model="negociacao.novoPrazoPagamento"/>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="6">
          <px-select-enum label="Tipo de frete"
                          class="required rounded-card-small"
                          v-model="negociacao.novoTipoFrete"
                          :items="tiposFretes"
                          :error="$v.negociacao.novoTipoFrete.$error"
                          @blur="$v.negociacao.novoTipoFrete.$touch()"
                          @change="onChangeTipoFrete"/>
        </v-col>
        <v-col cols="12" sm="6">
          <px-input-money
            label="Valor do frete"
            ref="novoValorFrete"
            class="required rounded-card-small"
            :disabled="isFob"
            v-model="negociacao.novoTotalTransporte"/>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <px-input-number label="Prazo de embarque (Dias úteis)"
                           class="required rounded-card-small"
                           v-model="negociacao.novoPrazoEntrega"
                           :error="$v.negociacao.novoPrazoEntrega.$error"
                           @blur="$v.negociacao.novoPrazoEntrega.$touch()"/>
        </v-col>
      </v-row>
  </div>
</template>

<script>
import { FormaPagamento, TipoFrete } from 'px-business-components';
import { required, requiredIf } from 'vuelidate/lib/validators';

export default {
  props: {
    negociacao: Object,
  },
  data() {
    return {
      dialog: false,
      formasPagamentos: FormaPagamento.values,
      tiposFretes: TipoFrete.values,
    };
  },
  computed: {
    isAvista() {
      return this.negociacao.novaFormaPagemento !== FormaPagamento.keys.A_PRAZO.key;
    },
    isFob() {
      return this.negociacao.novoTipoFrete === TipoFrete.keys.FOB.key;
    },
    prazoClass() {
      return this.isAvista ? 'rounded-card-small' : 'required rounded-card-small';
    },
  },
  methods: {
    onChangeFormaPagamento() {
      this.negociacao.novoPrazoPagamento = null;
    },
    onChangeTipoFrete() {
      // workaround para zerar v-money
      this.$refs.novoValorFrete.$el.getElementsByTagName('input')[0].value = 0;
      this.negociacao.novoValorFrete = 0;
    },
    validate() {
      this.$v.$touch();
      return this.$v.$invalid;
    },
  },
  validations: {
    negociacao: {
      novaFormaPagemento: {
        required,
      },
      novoPrazoPagamento: {
        required: requiredIf(function () {
          return !this.isAvista;
        }),
      },
      novoTipoFrete: {
        required,
      },
      novoPrazoEntrega: {
        required,
      },
    },
  },
};
</script>
