import { render, staticRenderFns } from "./CardDetalhesComprador.vue?vue&type=template&id=1fd09989&scoped=true"
import script from "./CardDetalhesComprador.vue?vue&type=script&lang=js"
export * from "./CardDetalhesComprador.vue?vue&type=script&lang=js"
import style0 from "./CardDetalhesComprador.vue?vue&type=style&index=0&id=1fd09989&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fd09989",
  null
  
)

export default component.exports