<template>
  <div>
    <v-card outlined class="rounded-card">
      <v-subheader class="font-weight-bold text--primary">Produtos</v-subheader>
      <v-divider/>
      <v-list class="rounded-card">
        <template v-for="(produto, index) in produtos">
          <v-list-item :key="produto.id">
            <v-list-item-content>
              <v-row>
                <v-col cols="12" class="body-1">
                  <px-span-tooltip v-if="checkCodigo(produto)"
                                   top
                                   :text="labelCodigo(produto)"
                                   tooltip="Código do item" />
                  {{ produto.produto.descricao }}
                  <span>
                    ({{produto.quantidade
                      | number(getCurrencyFormat(produto.produto.casasDecimais)) }}
                     {{produto.unidadeMedida.descricao}})
                  </span>
                  <px-situacao-cotacao-produto-fornecedor
                    v-if="!isItemValido(produto)"
                    :situacao="produto.situacao"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="3">
                  <span class="caption font-weight-bold">Código referência do fornecedor</span>
                  <div class="large-text">
                    {{ produto.produto.codigoFornecedor | naoInformado }}
                  </div>
                </v-col>
                <v-col cols="12" sm="3">
                  <span class="caption font-weight-bold">Especificacao</span>
                  <div class="large-text">
                    {{ produto.especificacaoProduto | naoInformado }}
                  </div>
                </v-col>
                <v-col cols="12" sm="3">
                  <span class="caption font-weight-bold">Observação externa do produto</span>
                  <div class="large-text">
                    {{ observacaoExterna(produto) | naoInformado }}
                  </div>
                </v-col>
                <v-col cols="12" sm="3">
                  <span class="caption font-weight-bold">Anexos</span>
                  <div v-if="produto.arquivos.length">
                    <px-anexo-chip :files="produto.arquivos"
                                   :download-base-path="downloadBasePath"/>
                  </div>
                  <div v-else>
                    Nenhum anexo informado
                  </div>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="index !== (produtos.length - 1)"
                     :key="`v-div${produto.id}`"/>
        </template>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import { SituacoesCotacao } from 'px-business-components';
import { CasaDecimalUtils } from 'px-components';

export default {
  props: {
    produtos: Array,
    solicitacaoId: [String, Number],
  },
  computed: {
    isItemValido() {
      return item => SituacoesCotacao.ABERTAS.includes(item.situacao);
    },
    downloadBasePath() {
      return `external/solicitacao/${this.solicitacaoId}/file`;
    },
    labelCodigo() {
      return item => `${item.produto.codigo} - `;
    },
    checkCodigo() {
      return item => item.produto.codigo;
    },
    getCurrencyFormat() {
      return item => CasaDecimalUtils.getCurrencyFormat(item);
    },
    observacaoExterna() {
      return produto => produto.observacaoExterna ?? produto.produto.observacaoExterna;
    },
  },
};
</script>
