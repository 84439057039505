import { render, staticRenderFns } from "./MinhaRespostaProdutosTable.vue?vue&type=template&id=d84399ee&scoped=true"
import script from "./MinhaRespostaProdutosTable.vue?vue&type=script&lang=js"
export * from "./MinhaRespostaProdutosTable.vue?vue&type=script&lang=js"
import style0 from "./MinhaRespostaProdutosTable.vue?vue&type=style&index=0&id=d84399ee&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d84399ee",
  null
  
)

export default component.exports