<template>
  <v-card class="elevation-1 rounded-card">
    <v-card-title class="headline">{{ cardTitle }}</v-card-title>
    <v-card-text>
      <px-negociacao-diff-dados-basicos
        v-if="negociacao.negociacao.campos.length"
        :negociacao="negociacao"/>
      <v-divider v-if="negociacao.negociacao.campos.length && negociacao.produtos.length"/>
      <px-negociacao-diff-dados-produtos v-if="negociacao.produtos.length"
                                         :negociacao="negociacao"/>
      <v-row dense>
        <v-col cols="12">
          <span class="font-weight-bold">Mensagem</span>
          <div class="large-text">
            {{ negociacao.message }}
          </div>
        </v-col>
      </v-row>
      <v-row dense v-if="negociacao.arquivos.length">
        <v-col cols="12">
          <span class="font-weight-bold">Arquivos</span>
          <px-anexo-chip :files="negociacao.arquivos"
                         :download-base-path="downloadFilePath"/>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="showActions">
      <v-spacer/>
      <px-negociacao-alterar-resposta :negociacao-comprador="negociacao"
                                      :cotacao-id="cotacaoId"
                                      @movimentar="onMovimentar"/>
<!--      <v-btn color="primary"-->
<!--             :loading="loadingAceitar"-->
<!--             :disabled="loadingRecusar"-->
<!--             @click="onClickAceitar">-->
<!--        Aceitar-->
<!--      </v-btn>-->
    </v-card-actions>
  </v-card>
</template>

<script>
import { cloneDeep } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import { TipoNegociacao, SituacoesCotacao } from 'px-business-components';
import NotificacaoService from './negociacao-service';
import NegociacaoDiffDadosBasicos from './NegociacaoDiffDadosBasicos.vue';
import NegociacaoDiffDadosProdutos from './NegociacaoDiffDadosProdutos.vue';
import NegociacaoAlterarResposta from './NegociacaoAlterarResposta.vue';

export default {
  props: {
    negociacao: Object,
    showActions: {
      type: Boolean,
      default: false,
    },
    cotacaoId: {
      type: String,
      required: true,
    },
    isFornecedor: {
      type: Boolean,
    },
  },
  data() {
    return {
      dialog: false,
      message: null,
      hasFileLoading: false,
      arquivos: [],
      loadingRecusar: false,
      loadingAceitar: false,
    };
  },
  computed: {
    cardTitle() {
      if (this.negociacao.tipo === TipoNegociacao.keys.SOLICITACAO.key) {
        return 'Negociação solicitada';
      }
      return 'Resposta da negociação';
    },
    downloadFilePath() {
      if (this.isFornecedor) {
        return `external/negociacoes/${this.cotacaoId}/download-fornecedor`;
      }
      return `external/negociacoes/${this.cotacaoId}/download-comprador`;
    },
  },
  methods: {
    validateForm() {
      if (this.hasFileLoading) {
        this.$toast('Aguarde a finalização do envio do(s) arquivo(s)', { color: 'error' });
        return true;
      }
      this.$v.$touch();
      return this.$v.$invalid;
    },
    onFileLoad(loading) {
      this.hasFileLoading = loading;
    },
    onClickRecusar() {
      if (this.validateForm()) {
        return;
      }
      this.loadingRecusar = true;
      const negociacao = {
        resposta: this.negociacao.resposta,
        tipo: TipoNegociacao.keys.RESPOSTA.key,
        message: this.message,
        arquivos: this.arquivos,
        negociacao: {
          campos: [],
        },
        produtos: [],
      };

      this.responder(negociacao);
    },
    onClickAceitar() {
      if (this.validateForm()) {
        return;
      }
      this.loadingAceitar = true;
      const produtos = cloneDeep(this.negociacao.produtos
        .filter(prod => SituacoesCotacao.ABERTAS.includes(
          prod.respostaProduto.cotacaoProduto.situacao,
        )));
      produtos.forEach((produto) => {
        // eslint-disable-next-line no-param-reassign
        delete produto.id;
      });
      const negociacao = {
        resposta: this.negociacao.resposta,
        tipo: TipoNegociacao.keys.RESPOSTA.key,
        message: this.message,
        arquivos: this.arquivos,
        negociacao: cloneDeep(this.negociacao.negociacao),
        produtos,
      };

      this.responder(negociacao);
    },
    responder(negociacao) {
      NotificacaoService.responder(this.cotacaoId, negociacao)
        .then(() => {
          this.$emit('movimentar');
          this.$toast('Resposta enviada com sucesso', { color: 'success' });
        })
        .finally(() => {
          this.loadingRecusar = false;
          this.loadingAceitar = false;
        });
    },
    onMovimentar() {
      this.$emit('movimentar');
    },
  },
  validations: {
    message: {
      required,
    },
  },
  components: {
    pxNegociacaoDiffDadosBasicos: NegociacaoDiffDadosBasicos,
    pxNegociacaoDiffDadosProdutos: NegociacaoDiffDadosProdutos,
    pxNegociacaoAlterarResposta: NegociacaoAlterarResposta,
  },
};
</script>
