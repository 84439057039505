<template>
  <px-custom-dialog v-model="dialog" :width="width">
    <template v-slot:activator="{ on }">
      <v-btn color="warning"
             depressed
             class="ml-2 normal-btn-text rounded-tag-small"
             v-on="on">
        Responder negociação
      </v-btn>
    </template>
    <template v-slot:title>
      <span class="font-weight-bold">Responder negociação</span>
    </template>
    <template>
      <px-negociacao-alterar-resposta-dados-basicos
        ref="negociacaoDados"
        v-if="dialog"
        :negociacao="negociacao"/>
      <px-negociacao-alterar-resposta-produtos
        ref="negociacaoProdutos"
        v-if="dialog"
        :negociacao="negociacao"/>
      <v-row class="mt-3" justify="center">
        <v-col cols="11">
          <v-textarea
            outlined
            auto-grow
            class="required rounded-card-small"
            label="Mensagem para o comprador"
            counter="1000"
            rows="2"
            v-model="message"
            :error="$v.message.$error"
            @blur="$v.message.$touch()"
          />
        </v-col>
        <v-col cols="1">
          <div class="btn-file">
            <px-anexo badge-color="secondary"
                      v-model="arquivos"
                      :max="3"
                      upload-base-path="external/files"
                      @loading="onFileLoad"/>
          </div>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <div class="btn-actions">
        <v-btn color="primary"
               depressed
               class="normal-btn-text rounded-tag-small"
               :loading="loadingSave"
               @click="send">
          Enviar
        </v-btn>
      </div>
      <div class="btn-actions">
        <v-btn class="normal-btn-text mr-3"
               text
               @click="close">
          Cancelar
        </v-btn>
      </div>
    </template>
  </px-custom-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import SolicitacaoService from '@/modules/cotacao/solicitacao-service';
import NotificacaoService from '@/modules/negociacao/negociacao-service';
import { buildNegociacao, buildFromNegociacao } from './negociacao-utils';
import NegociacaoAlterarRespostaDadosBasicos from './NegociacaoAlterarRespostaDadosBasicos.vue';
import NegociacaoAlterarRespostaProdutos from './NegociacaoAlterarRespostaProdutos.vue';

export default {
  props: {
    negociacaoComprador: Object,
    cotacaoId: String,
  },
  data() {
    return {
      loading: false,
      hasFileLoading: false,
      loadingSave: false,
      dialog: false,
      initialNegociacao: null,
      negociacao: {
        novaFormaPagemento: null,
        novoPrazoPagamento: null,
        novoTipoFrete: null,
        novoPrazoEntrega: null,
        novoTotalTransporte: null,
        produtos: [],
      },
      message: null,
      arquivos: [],
    };
  },
  computed: {
    width() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return '100%';
      }
      return '550px';
    },
  },
  methods: {
    validate() {
      if (this.hasFileLoading) {
        this.$toast('Aguarde a finalização do envio do(s) arquivo(s)', { color: 'error' });
        return true;
      }
      this.$v.$touch();
      return this.$v.$invalid;
    },
    close() {
      this.dialog = false;
    },
    onFileLoad(loading) {
      this.hasFileLoading = loading;
    },
    send() {
      if (this.$refs.negociacaoDados.validate() || this.$refs.negociacaoProdutos.validate()
        || this.validate()) {
        return;
      }
      const negociacao = buildFromNegociacao(this.negociacao, this.initialNegociacao, this.message,
        this.arquivos,
        this.solicitacao.respostaCotacao);
      this.responder(negociacao);
    },
    getSolicitacao() {
      this.loading = true;
      SolicitacaoService.getSolicitacao(this.cotacaoId)
        .then((response) => {
          this.solicitacao = response.data;
          this.buildInitialState();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    buildInitialState() {
      this.initialNegociacao = buildNegociacao(this.solicitacao);
      this.negociacao = buildNegociacao(this.solicitacao);
    },
    responder(negociacao) {
      this.loadingAceitar = true;
      NotificacaoService.responder(this.cotacaoId, negociacao)
        .then(() => {
          this.$emit('movimentar');
          this.$toast('Resposta enviada com sucesso', { color: 'success' });
          this.close();
        })
        .finally(() => {
          this.loadingAceitar = false;
        });
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      if (this.solicitacao) {
        this.buildInitialState();
      }
    },
  },
  created() {
    this.getSolicitacao();
  },
  validations: {
    message: {
      required,
    },
  },
  components: {
    pxNegociacaoAlterarRespostaDadosBasicos: NegociacaoAlterarRespostaDadosBasicos,
    pxNegociacaoAlterarRespostaProdutos: NegociacaoAlterarRespostaProdutos,
  },
};
</script>
<style scoped>
.btn-actions {
  margin-right: 5px;
  margin-bottom: 10px;
}
.btn-file {
  margin-top: 10px;
  margin-right: 5px;
}
</style>
