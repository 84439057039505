<template>
  <px-lateral-dialog persistent v-model="dialog" width="600">
    <template v-slot:activator="{ on }">
      <v-btn color="primary"
             class="normal-btn-text rounded-tag-small"
             v-on="on">
        Aprovar
      </v-btn>
    </template>
    <template v-slot:title>
      Aprovar pedido de compra
    </template>
    <template>
      <v-row dense>
        <v-col cols="23">
          <v-checkbox
            v-model="isChecked"
            label="Confirmo o recebimento e acesso deste pedido de compra.">
            <template v-slot:label>
              <span class="body-1">Confirmo o recebimento e acesso deste pedido de compra.</span>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-textarea
            outlined
            auto-grow
            class="required"
            label="Mensagem para o comprador"
            counter="1500"
            rows="1"
            v-model="finalizacao.mensagemFornecedor"
            :error="$v.finalizacao.mensagemFornecedor.$error"
            @blur="$v.finalizacao.mensagemFornecedor.$touch()"
          />
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="text--primary body-2">
        <v-col cols="12">
          Como você avalia a empresa <b>{{ name }}</b> nesta venda?
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col cols="12">
          <v-rating
            hover
            background-color="grey"
            color="yellow darken-1"
            length="5"
            size="30"
            v-model="rating"
          ></v-rating>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea
            outlined
            rows="1"
            v-model="comentario"
            label="Comentário"
            counter="500"
            class="rounded-tag-small"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-spacer/>
      <v-btn color="primary"
             class="normal-btn-text rounded-tag-small"
             :loading="loading"
             :disabled="!isChecked"
             @click="confirm">
        Confirmar
      </v-btn>
      <v-btn @click="close">
        Cancelar
      </v-btn>
    </template>
  </px-lateral-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import validationMixin from '@/mixins/validation-mixin';
import FinalizacaoService from './finalizacao-service';

export default {
  props: {
    requestId: {
      type: String,
      required: true,
    },
    compraId: {
      type: [String, Number],
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
  },
  mixins: [validationMixin],
  data() {
    return {
      dialog: false,
      loading: false,
      isChecked: false,
      rating: 0,
      comentario: null,
      finalizacao: {
        mensagemFornecedor: null,
      },
    };
  },
  methods: {
    buildPayload() {
      if (this.rating) {
        return {
          compra: {
            ...this.finalizacao,
          },
          avaliacao: {
            compra: {
              id: this.compraId,
            },
            tipo: 'COMPRADOR',
            avaliacao: {
              pontuacao: this.rating,
              comentario: this.comentario,
            },
          },
        };
      }
      return {
        compra: {
          ...this.finalizacao,
        },
      };
    },
    close() {
      this.dialog = false;
    },
    confirm() {
      if (this.validateForm()) {
        return;
      }
      this.loading = true;
      FinalizacaoService.receberAprovar(this.requestId, this.buildPayload())
        .then(() => {
          this.$emit('update');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  validations: {
    finalizacao: {
      mensagemFornecedor: {
        required,
      },
    },
  },
};
</script>
