const DEFAULT_PRECISION = 14;

function defaultValue(situacao, produto) {
  return {
    cotacaoProduto: produto,
    situacao,
    precoUnitario: 0,
    especificacaoProduto: null,
    impostos: {
      tipoAplicacaoIcms: null,
      tipoAplicacaoIpi: null,
      icms: null,
      ipi: null,
      valorIcms: 0,
      valorIpi: 0,
      total: 0,
      totalProdutos: 0,
      percentualRateio: 0,
      frete: 0,
    },
    arquivos: [],
  };
}

export {
  // eslint-disable-next-line import/prefer-default-export
  defaultValue,
  DEFAULT_PRECISION,
};
