<template>
  <v-tooltip top>
    <template v-slot:activator="{ on: onTooltip }">
      <v-btn icon small v-on="onTooltip" @click="onClick">
        <v-icon small>
          mdi-clipboard-arrow-down
        </v-icon>
      </v-btn>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    tooltipText: {
      type: String,
      default: 'Replicar preço para os outros produtos',
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>
