<template>
  <v-card class="rounded-card card-shadow mt-3">
    <v-tabs v-model="tab">
      <v-tab class="small-spacing">Local de entrega</v-tab>
      <v-tab class="small-spacing">Local de faturamento</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card
          flat
          class="rounded-card"
        >
          <v-card-text class="text--primary">
            <px-finalizacao-detalhes-comprador is-entrega :finalizacao="finalizacao"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card
          flat
          class="rounded-card"
        >
          <v-card-text class="text--primary">
            <px-finalizacao-detalhes-comprador :finalizacao="finalizacao"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import pxFinalizacaoDetalhesComprador from './FinalizacaoDetalhesComprador.vue';

export default {
  props: {
    finalizacao: Object,
  },
  data() {
    return {
      tab: 0,
    };
  },
  components: { pxFinalizacaoDetalhesComprador },
};
</script>
