<template>
  <div class="cards mt-3">
    <div class="card-entrega">
      <v-card outlined class="rounded-card">
        <v-subheader class="font-weight-bold text--primary">Local de entrega</v-subheader>
        <v-divider/>
        <v-card-text>
          <px-detalhes-comprador :comprador="cotacao.compradorEntrega"/>
        </v-card-text>
      </v-card>
    </div>
    <div class="card-faturamento">
      <v-card outlined class="rounded-card">
        <v-subheader class="font-weight-bold text--primary">Local de faturamento</v-subheader>
        <v-divider/>
        <v-card-text>
          <px-detalhes-comprador :comprador="cotacao.comprador"/>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    cotacao: Object,
  },
};
</script>
<style scoped>
.cards {
  display: flex;
}
.card-entrega {
  min-width: 48%;
}
.card-faturamento {
  min-width: 50%;
  margin-left: auto;
}
</style>
