<template>
  <div>
    <v-form>
      <v-container class="mb-4">
        <v-row>
          <v-col cols="12" sm="8">
            <p>Ofereço frete grátis dependendo do valor comprado *</p>
            <v-radio-group v-model="resposta.frete.ofertaFreteGratis"
                           :error="$v.resposta.frete.ofertaFreteGratis.$error"
                           @change="onChangeOfereceCif"
                           required>
              <v-radio
                label="Sim"
                :value="true"
              />
              <v-radio
                label="Não"
                :value="false"
              />
            </v-radio-group>
          </v-col>
          <v-col class="d-flex" cols="12" sm="4">
            <px-input-money
              label="Valor mínimo para frete grátis"
              class="required rounded-tag-small"
              ref="valorMinimoFreteGratis"
              :disabled="!resposta.frete.ofertaFreteGratis"
              :error="$v.resposta.frete.valorMinimoFreteGratis.$error"
              @blur="$v.resposta.frete.valorMinimoFreteGratis.$touch()"
              v-model="resposta.frete.valorMinimoFreteGratis"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex" cols="12" sm="4">
            <px-select-enum class="required rounded-tag-small"
                            label="Tipo de frete"
                            :error="$v.resposta.frete.tipoFrete.$error"
                            @blur="$v.resposta.frete.tipoFrete.$touch()"
                            v-model="resposta.frete.tipoFrete"
                            :items="tiposFrete"
                            @change="onChangeTipoFrete"/>
          </v-col>
          <v-col class="d-flex" cols="12" sm="4">
            <px-input-number label="Prazo de embarque (Dias úteis)"
                            class="required rounded-tag-small"
                            :error="$v.resposta.frete.prazoEntrega.$error"
                            @blur="$v.resposta.frete.prazoEntrega.$touch()"
                            v-model="resposta.frete.prazoEntrega"/>
          </v-col>
          <v-col class="d-flex" cols="12" sm="4">
            <px-input-money
              label="Valor do frete"
              class="required rounded-tag-small"
              ref="totalTransporte"
              :disabled="isFob"
              :error="$v.resposta.frete.totalTransporte.$error"
              @blur="$v.resposta.frete.totalTransporte.$touch()"
              v-model="resposta.frete.totalTransporte"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              outlined
              v-model="resposta.frete.observacaoFrete"
              label="Observações do frete"
              counter="1500"
              class="rounded-tag-small"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-btn color="primary"
             class="normal-btn-text rounded-tag-small"
             @click="next">
        Continuar
      </v-btn>
      <v-btn text class="normal-btn-text" @click="previous">Voltar</v-btn>
    </v-form>
  </div>
</template>

<script>
import { TipoFrete } from 'px-business-components';
import { required } from 'vuelidate/lib/validators';
import validationMixin from '@/mixins/validation-mixin';

export default {
  props: {
    resposta: Object,
  },
  mixins: [validationMixin],
  data() {
    return {
      tiposFrete: TipoFrete.values,
    };
  },
  computed: {
    isFob() {
      return this.resposta.frete.tipoFrete === TipoFrete.keys.FOB.key;
    },
  },
  methods: {
    next() {
      if (this.validateForm()) {
        return;
      }
      this.$emit('next');
    },
    previous() {
      this.$emit('previous');
    },
    onChangeOfereceCif() {
      // workaround para zerar v-money
      this.$refs.valorMinimoFreteGratis.$el.getElementsByTagName('input')[0].value = 0;
      this.resposta.frete.valorMinimoFreteGratis = 0;
    },
    onChangeTipoFrete() {
      if (this.isFob) {
        // workaround para zerar v-money
        this.$refs.totalTransporte.$el.getElementsByTagName('input')[0].value = 0;
        this.resposta.frete.totalTransporte = 0;
      }
    },
  },
  validations() {
    const base = {
      resposta: {
        frete: {
          tipoFrete: {
            required,
          },
          prazoEntrega: {
            required,
          },
          totalTransporte: {
            required,
          },
          valorMinimoFreteGratis: {
            required,
          },
          ofertaFreteGratis: {
            required,
          },
        },
      },
    };
    if (this.resposta.frete.ofertaFreteGratis) {
      base.resposta.frete.valorMinimoFreteGratis.minValue = value => value > 0;
    }
    return base;
  },
};
</script>
