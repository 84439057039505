<template>
  <v-menu
    min-width="450px"
    v-model="opened"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-tooltip top>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn small icon v-on="{...on, ...onTooltip}">
            <v-icon small :color="iconColor">mdi-paperclip</v-icon>
          </v-btn>
        </template>
        <span>Adicionar especificação e anexos</span>
      </v-tooltip>
    </template>
    <v-card class="mx-auto">
      <v-card-title>
        {{ respostaProduto.cotacaoProduto.produto.descricao }}
      </v-card-title>
      <v-card-text class="text--primary">
        <v-row>
          <v-col cols="12">
            <px-anexo badge-color="primary"
                      v-model="respostaProduto.arquivos"
                      :max="3"
                      top
                      :bottom="false"
                      upload-base-path="external/files"
                      @loading="onFileLoad">
              <template v-slot:activator="{ on }">
                <v-btn small depressed
                       class="rounded-tag-small elevation-0"
                       color="" v-on="on">
                  Adicionar anexo
                </v-btn>
              </template>
            </px-anexo>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              outlined
              auto-grow
              class="required rounded-tag-small"
              label="Especificação"
              counter="1500"
              rows="3"
              v-model="respostaProduto.especificacaoProduto"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn @click="onClickConcluir" color="primary"
                   class="rounded-tag-small normal-btn-text elevation-0">
              Concluir
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    respostaProduto: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      opened: false,
    };
  },
  computed: {
    iconColor() {
      if (this.respostaProduto.especificacaoProduto || this.respostaProduto.arquivos.length) {
        return 'success';
      }
      return '';
    },
  },
  methods: {
    // todo: solve file loading
    onFileLoad(loading) {
      this.$emit('file-loading', loading);
    },
    onClickConcluir() {
      this.opened = false;
    },
  },
};
</script>
