<template>
  <px-lateral-dialog v-model="dialog" width="800">
    <template v-slot:activator="{ on }">
      <v-btn color="error"
             class="mr-3 normal-btn-text rounded-tag-small"
             v-on="on">
        Recusar
      </v-btn>
    </template>
    <template v-slot:title>
      Recusar solicitação
    </template>
    <template>
      <v-row class="mt-3">
        <v-col cols="12">
          <v-textarea
            outlined
            auto-grow
            class="required rounded-card"
            label="Motivo da recusa"
            counter="1500"
            rows="4"
            v-model="motivoRecusa"
            :error="$v.motivoRecusa.$error"
            @blur="$v.motivoRecusa.$touch()"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-spacer/>
      <v-btn text class="normal-btn-text" @click="close">
        Cancelar
      </v-btn>
      <v-btn color="primary"
             class="normal-btn-text rounded-tag-small"
             :loading="loading" @click="onClickRecusar">
        Recusar
      </v-btn>
    </template>
  </px-lateral-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import validationMixin from '@/mixins/validation-mixin';
import FinalizacaoService from './finalizacao-service';

export default {
  props: {
    compra: Object,
    compraId: String,
  },
  mixins: [validationMixin],
  data() {
    return {
      dialog: false,
      loading: false,
      motivoRecusa: null,
    };
  },
  methods: {
    recusar() {
      this.loading = true;
      FinalizacaoService.recusar(this.compraId, this.buildCompra())
        .then(() => {
          this.$toast('Solicitação recusada com sucesso', { color: 'success' });
          this.$emit('movimentar');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onClickRecusar() {
      if (this.validateForm()) {
        return;
      }
      this.recusar();
    },
    close() {
      this.dialog = false;
    },
    buildCompra() {
      return {
        id: this.compra.id,
        motivoRecusa: this.motivoRecusa,
      };
    },
  },
  validations: {
    motivoRecusa: {
      required,
    },
  },
};
</script>
