<template>
  <div>
    <div class="px-5 mt-3">
      <v-row>
        <v-col cols="12" sm="4">
          <v-btn class="mb-4 mr-1" icon x-large color="primary" link @click="onClickBack">
            <v-icon>
              mdi-arrow-left
            </v-icon>
          </v-btn>
          <span class="display-1 font-weight-bold">Responder</span>
        </v-col>
        <v-col cols="12" sm="8" v-if="solicitacao" class="text-right body-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                {{ solicitacao.fornecedor.pessoa.nome }}
                ({{ solicitacao.fornecedor.pessoa.cnpj | cnpj}})
              </span>
            </template>
            <span>Empresa fornecedora</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </div>
    <div v-if="loading" class="text-center">
      <px-circular-loading/>
    </div>
    <div v-if="!loading && solicitacao">
      <div>
        <px-resposta-card-cotacao :cotacao="solicitacao.cotacao"/>
      </div>
      <div class="mt-3">
        <px-resposta-cadastro :solicitacao="solicitacao"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SolicitacaoService from '@/modules/cotacao/solicitacao-service';
import RespostaCardCotacao from './RespostaCardCotacao.vue';
import RespostaCadastro from './RespostaCadastro.vue';

export default {
  data() {
    return {
      loading: false,
      solicitacao: null,
    };
  },
  computed: {
    ...mapGetters({
      cotacaoId: 'cotacaoId',
    }),
  },
  methods: {
    getSolicitacao() {
      this.loading = true;
      return SolicitacaoService.getSolicitacao(this.cotacaoId)
        .then((response) => {
          this.solicitacao = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onClickBack() {
      this.$router.go(-1);
    },
  },
  created() {
    this.getSolicitacao();
  },
  components: {
    pxRespostaCardCotacao: RespostaCardCotacao,
    pxRespostaCadastro: RespostaCadastro,
  },
};
</script>
