<template>
  <v-card class="rounded-card">
    <v-card-text>
      <v-row dense>
        <v-col cols="12" sm="7">
          <p class="mb-1">
              <span class="headline text--primary font-weight-bold">
                {{ finalizacao.comprador.pessoa.nome }}
              </span>
            <px-situacao-compra-tag :situacao="finalizacao.situacao"/>
          </p>
        </v-col>
        <v-col cols="12" sm="5" class="text-right"
               v-if="isPendenteFornecedor">
          <px-finalizacao-recusar-dialog :compra-id="compraId"
                                         :compra="finalizacao"
                                         @movimentar="onMovimentar"/>
          <px-finalizacao-aprovar-dialog :compra-id="compraId"
                                         :compra="finalizacao"
                                         v-if="isAprovacaoEnabled"
                                         @movimentar="onMovimentar"/>
          <px-finalizacao-confirm-approve-dialog
            :request-id="compraId"
            :compra-id="finalizacao.id"
            v-if="!isAprovacaoEnabled"
            :name="finalizacao.comprador.pessoa.nome"
            @update="onMovimentar"
          />
        </v-col>
      </v-row>
      <p class="mt-2">
        <v-icon>mdi-domain</v-icon>
        {{ finalizacao.comprador.pessoa.cnpj | cnpj }}
      </p>
      <p>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-icon>mdi-map-marker</v-icon>
              {{ finalizacao.compradorEntrega.endereco | endereco }}
            </span>
          </template>
          <span>Endereço de entrega</span>
        </v-tooltip>
      </p>
      <div class="text--primary">
        <v-row dense>
          <v-col cols="12" sm="3">
            <span class="caption font-weight-bold">Forma de pagamento</span>
            <div>
              <px-forma-pagamento :forma-pagamento="finalizacao.formaPagamento"
                                  :prazo-pagamento="finalizacao.prazoPagamento"/>
            </div>
          </v-col>
          <v-col cols="12" sm="3">
            <span class="caption font-weight-bold">Tipo de frete</span>
            <div>
              {{ finalizacao.frete.tipoFrete }}
            </div>
          </v-col>
          <v-col cols="12" sm="3">
            <span class="caption font-weight-bold">{{ labelFrete }}</span>
            <div>
              {{ finalizacao.frete.prazoEntrega | pluralize(['dia útil', 'dias úteis']) }}
            </div>
          </v-col>
          <v-col cols="12" sm="3">
            <span class="caption font-weight-bold">Finalidade da Compra</span>
            <div v-if="finalizacao.finalidadeCompra">
              <px-finalidade-compra-value :finalidade-compra="finalizacao.finalidadeCompra"/>
            </div>
            <div v-else>
              Não Informado
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="3">
            <span class="caption font-weight-bold">Mensagem do comprador</span>
            <div class="large-text">
              {{ finalizacao.mensagem | naoInformado }}
            </div>
          </v-col>
          <v-col cols="12" sm="3">
            <span class="caption font-weight-bold">Anexos</span>
            <div v-if="finalizacao.arquivos.length">
              <px-anexo-chip :files="finalizacao.arquivos"
                             :download-base-path="downloadFilePath"/>
            </div>
            <div v-else>
              Nenhum anexo informado
            </div>
          </v-col>
          <v-col cols="12" sm="3">
            <span class="caption font-weight-bold">Mensagem para o comprador</span>
            <div class="large-text">
              {{ finalizacao.mensagemFornecedor | naoInformado }}
            </div>
          </v-col>
          <v-col cols="12" sm="3">
            <span class="caption font-weight-bold">Data de Faturamento</span>
            <div>
              {{ finalizacao.dataFaturamento | date | naoInformado }}
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="3" v-if="isRecusadoCancelado">
            <span class="caption font-weight-bold">Motivo da recusa/cancelamento</span>
            <div class="large-text">
              {{ finalizacao.motivoRecusa | naoInformado }}
            </div>
          </v-col>
          <v-col cols="12" sm="3" v-if="!hasTransport">
            <span class="caption font-weight-bold">Transportadora</span>
            <div>
              {{ finalizacao.transportadora.pessoa.nome | naoInformado }}
            </div>
          </v-col>
          <v-col cols="12" sm="3" v-if="finalizacao.tipoNotaEntrada">
            <span class="caption font-weight-bold">Tipo de nota de entrada</span>
            <div>
              <px-span-tooltip top
                  :text="notaEntrada"
                  :tooltip="finalizacao.tipoNotaEntrada.descricao"/>
            </div>
          </v-col>
          <v-col cols="12" sm="3" v-if="finalizacao.cfop">
            <span class="caption font-weight-bold">CFOP da compra</span>
            <div>
              <px-span-tooltip top
                  :text="cfop"
                  :tooltip="`${finalizacao.cfop.codigo} - ${finalizacao.cfop.descricao}`"/>
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="3" v-if="finalizacao.modoPagamento">
            <span class="caption font-weight-bold">Modo de pagamento</span>
            <div>
              {{ finalizacao.modoPagamento.descricao }}
            </div>
          </v-col>
        </v-row>
        <v-row dense class="mt-3">
          <v-col cols="12" v-if="finalizacao.usuario">
            <span class="caption font-weight-bold">Comprador responsável</span>
            <div>
              {{ finalizacao.usuario.nome }}
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { SituacaoCompra, TipoFrete } from 'px-business-components';
import Vue from 'vue';
import FinalizacaoConfirmApproveDialog from './FinalizacaoConfirmApproveDialog.vue';
import FinalizacaoAprovarDialog from './FinalizacaoAprovarDialog.vue';
import FinalizacaoRecusarDialog from './FinalizacaoRecusarDialog.vue';

export default {
  props: {
    finalizacao: Object,
    compraId: String,
  },
  computed: {
    notaEntrada() {
      return Vue.filter('truncate')(this.finalizacao.tipoNotaEntrada.descricao, 20);
    },
    cfop() {
      return `${this.finalizacao.cfop.codigo} - ${Vue.filter('truncate')(this.finalizacao.cfop.descricao, 15)}`;
    },
    isAprovacaoEnabled() {
      return !this.finalizacao.entidadeComprador.pularAprovacaoFornecedor;
    },
    isPendenteFornecedor() {
      return this.finalizacao.situacao === SituacaoCompra.keys.PENDENTE_FORNECEDOR.key;
    },
    isRecusadoCancelado() {
      return this.finalizacao.situacao === SituacaoCompra.keys.RECUSADA.key
         || this.finalizacao.situacao === SituacaoCompra.keys.CANCELADA.key;
    },
    downloadFilePath() {
      return `external/pedidos/${this.compraId}/file`;
    },
    hasTransport() {
      return this.finalizacao.transportadora === null;
    },
    isCIF() {
      return this.finalizacao.frete.tipoFrete === TipoFrete.keys.CIF.key;
    },
    labelFrete() {
      return this.isCif ? 'Entrega' : 'Embarque';
    },
  },
  methods: {
    onMovimentar() {
      this.$emit('movimentar');
    },
  },
  components: {
    pxFinalizacaoAprovarDialog: FinalizacaoAprovarDialog,
    pxFinalizacaoRecusarDialog: FinalizacaoRecusarDialog,
    pxFinalizacaoConfirmApproveDialog: FinalizacaoConfirmApproveDialog,
  },
};
</script>
