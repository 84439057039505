<template>
  <div>
    <v-row dense>
      <v-col cols="12" class="font-weight-bold">
        Alterações solicitadas nos produtos:
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" v-for="negociacaoProduto in negociacao.produtos"
             :key="negociacaoProduto.id">
        <px-negociacao-diff-campo campo="PRECO_UNITARIO"
                                  :value-changed-class="valueClass"
                                  :precision="negociacaoProduto.respostaProduto
                                    .cotacaoProduto.produto.casasDecimais"
                                  :description-param="description(negociacaoProduto)"
                                  :codigo-fornecedor="negociacaoProduto.respostaProduto
                                    .cotacaoProduto.produto.codigoFornecedor"
                                  :negociacao="negociacaoProduto.negociacao"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { TipoNegociacao } from 'px-business-components';

export default {
  props: {
    negociacao: Object,
  },
  computed: {
    description() {
      return negociacaoProduto => (negociacaoProduto.respostaProduto.cotacaoProduto.produto.codigo
        ? `(${negociacaoProduto.respostaProduto.cotacaoProduto.produto.codigo}) ${negociacaoProduto.respostaProduto.cotacaoProduto.produto.descricao}`
        : `${negociacaoProduto.respostaProduto.cotacaoProduto.produto.descricao}`);
    },
    valueClass() {
      if (this.negociacao.tipo === TipoNegociacao.keys.RESPOSTA.key) {
        return 'green--text font-weight-bold';
      }
      return 'orange--text font-weight-bold';
    },
  },
};
</script>
