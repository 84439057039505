<template>
  <div>
    <div class="px-5 mt-3">
      <v-row v-if="solicitacao">
        <v-col cols="12" sm="6">
          <span class="display-1 font-weight-bold">
            Solicitação de cotação
          </span><br>
          <span class="body-2">
            Código da cotação proxpect - {{ solicitacao.cotacao.id }}
          </span>
        </v-col>
        <v-col cols="12" sm="6" class="text-right body-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                {{ solicitacao.fornecedor.pessoa.nome }}
                ({{ solicitacao.fornecedor.pessoa.cnpj | cnpj}})
              </span>
            </template>
            <span>Empresa fornecedora</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </div>

    <div v-if="loading" class="text-center">
      <px-circular-loading/>
    </div>
    <div v-if="!loading && solicitacao" class="mt-5">
      <v-row>
        <v-col cols="12">
          <px-aviso-plataforma-fornecedor-simple v-if="!loading && solicitacao"/>
        </v-col>
      </v-row>
      <div>
        <px-card-solicitacao-dados-basicos :solicitacao="solicitacao"
                                           :id-solicitacao="cotacaoId"
                                           @movimentar="onMovimentar"/>
      </div>
    </div>
    <div id="negociacoes">
      <px-negociacao v-if="!loading && isRespondida" :cotacao-id="cotacaoId"/>
    </div>
    <px-auth-dialog v-model="showAuth"
                    :cotacao-id="cotacaoId"
                    @validate="onValidate"/>
<!--    <px-aviso-plataforma-fornecedor v-if="!loading && solicitacao"/>-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SituacaoRespostaCotacaoProduto } from 'px-business-components';
import eventsMixin from '@/mixins/events-mixin';
import Negociacao from '@/modules/negociacao/Negociacao.vue';
import AuthDialog from './AuthDialog.vue';
import CardSolicitacaoDadosBasicos from './CardSolicitacaoDadosBasicos.vue';
import AuthService from './auth-service';
import SolicitacaoService from './solicitacao-service';

export default {
  mixins: [eventsMixin],
  data() {
    return {
      showAuth: false,
      loading: false,
      solicitacao: null,
    };
  },
  computed: {
    ...mapGetters({
      cotacaoId: 'cotacaoId',
      isAuthenticated: 'isAuthenticated',
      tokens: 'tokens',
    }),
    isRespondida() {
      return this.solicitacao && this.solicitacao.situacaoSolicitacaoResposta
        === SituacaoRespostaCotacaoProduto.keys.RESPONDIDA.key;
    },
  },
  methods: {
    validate() {
      this.loading = true;
      AuthService.validar(this.cotacaoId, { token: this.tokens })
        .then(() => this.getSolicitacao())
        .catch(() => {
          this.showAuth = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSolicitacao() {
      return SolicitacaoService.getSolicitacao(this.cotacaoId)
        .then((response) => {
          this.solicitacao = response.data;
          this.createEventAccess(this.eventsResource.COTACAO,
            { solicitacao: this.cotacaoId }, true);
        });
    },
    onValidate() {
      this.loading = true;
      this.getSolicitacao()
        .finally(() => {
          this.loading = false;
        });
    },
    onMovimentar() {
      this.getSolicitacao();
    },
  },
  created() {
    if (!this.isAuthenticated) {
      this.showAuth = true;
      return;
    }
    this.validate();
  },
  components: {
    pxAuthDialog: AuthDialog,
    pxCardSolicitacaoDadosBasicos: CardSolicitacaoDadosBasicos,
    pxNegociacao: Negociacao,
  },
};
</script>
