<template>
  <div v-if="isEntrega">
    <v-row dense>
      <v-col cols="12" sm="6" md="7">
        <p class="mb-1">
              <span class="title font-weight-bold text--primary">
                {{ finalizacao.compradorEntrega.pessoa.nome }}
              </span>
        </p>
      </v-col>
    </v-row>
    <p class="mt-2">
      <v-icon>mdi-domain</v-icon>
      {{ finalizacao.compradorEntrega.pessoa.cnpj | cnpj }}
    </p>
    <p>
      <v-icon>mdi-map-marker</v-icon>
      {{ finalizacao.compradorEntrega.endereco | endereco }}
    </p>
  </div>
  <div v-else>
    <v-row dense>
      <v-col cols="12" sm="6" md="7">
        <p class="mb-1">
              <span class="title font-weight-bold text--primary">
                {{ finalizacao.comprador.pessoa.nome }}
              </span>
        </p>
      </v-col>
    </v-row>
    <p class="mt-2">
      <v-icon>mdi-domain</v-icon>
      {{ finalizacao.comprador.pessoa.cnpj | cnpj }}
    </p>
    <p>
      <v-icon>mdi-map-marker</v-icon>
      {{ finalizacao.comprador.endereco | endereco }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    finalizacao: Object,
    isEntrega: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
