<template>
  <div>
    <v-card class="rounded-card card-shadow">
      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="8">
            <p class="mb-1">
              <span class="headline text--primary font-weight-bold">
                {{ solicitacao.cotacao.comprador.pessoa.nome }}
              </span>
              <px-situacao-cotacao-tag :situacao="solicitacao.cotacao.situacao"/>
            </p>
          </v-col>
          <v-col cols="12" sm="4"
                 class="text-right"
                 v-if="isNaoRespondida && isCotacaoAberta">
            <px-recusar-cotacao
              :loading="loadingRecusar"
              @change="onRecusar"/>
            <v-btn color="primary"
                   class="ml-2 normal-btn-text rounded-tag-small"
                   @click="onClickResponder">
              Responder
            </v-btn>
          </v-col>
          <v-col cols="12" sm="4"
                 class="text-right"
                 v-if="isRespondida">
            <v-btn color="primary"
                   class="ml-2 normal-btn-text rounded-tag-small"
                   @click="onClickMinhaResposta">
              Ver minha resposta
            </v-btn>
          </v-col>
          <v-col cols="12" sm="4"
                 class="text-right"
                 v-if="isRecusada">
            <px-situacao-resposta-produto-chip
              :situacao="solicitacao.situacaoSolicitacaoResposta"/>
          </v-col>
        </v-row>
        <p class="mt-2">
          <v-icon>mdi-domain</v-icon>
          {{ solicitacao.cotacao.comprador.pessoa.cnpj | cnpj }}
        </p>
        <p>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <v-icon>mdi-map-marker</v-icon>
                {{ solicitacao.cotacao.compradorEntrega.endereco | endereco }}
              </span>
            </template>
            <span>Endereço de entrega</span>
          </v-tooltip>
        </p>
        <div class="text--primary">
          <v-row dense>
            <v-col cols="12" sm="3">
              <span class="caption font-weight-bold">Tipo de frete</span>
              <div>
                {{ solicitacao.cotacao.tipoFrete | naoInformado }}
              </div>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="caption font-weight-bold">Prazo de entrega</span>
              <div>
                {{ solicitacao.cotacao.prazoEntrega | date | naoInformado }}
              </div>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="caption font-weight-bold">Prazo de resposta</span>
              <div>
                {{ solicitacao.cotacao.validade | date | naoInformado }}
              </div>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="caption font-weight-bold">Finalidade da Compra</span>
              <div v-if="solicitacao.cotacao.finalidadeCompra">
                <px-finalidade-compra-value
                :finalidade-compra="solicitacao.cotacao.finalidadeCompra"/>
              </div>
              <div v-else>
                Não Informado
              </div>
            </v-col>
          </v-row>
          <v-row dense class="mt-3">
            <v-col cols="12" sm="3">
              <span class="caption font-weight-bold">Forma de pagamento</span>
              <div>
                <px-forma-pagamento v-if="solicitacao.cotacao.formaPagamento"
                                    :forma-pagamento="solicitacao.cotacao.formaPagamento"
                                    :prazo-pagamento="solicitacao.cotacao.prazoPagamento"/>
                <span v-else>Não informado</span>
              </div>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="caption font-weight-bold">Observação</span>
              <div class="large-text">
                {{ solicitacao.cotacao.observacao | naoInformado }}
              </div>
            </v-col>
            <v-col cols="12" sm="3" v-if="solicitacao.outroMotivo">
              <span class="caption font-weight-bold">Motivo da recusa</span>
              <div class="large-text">
                {{ solicitacao.outroMotivo }}
              </div>
            </v-col>
          </v-row>
          <v-row dense class="mt-3">
            <v-col cols="12" v-if="solicitacao.cotacao.usuario">
              <span class="caption font-weight-bold">Comprador responsável</span>
              <div class="lage-text">
                {{ solicitacao.cotacao.usuario.nome }}
              </div>
            </v-col>
          </v-row>
          <v-row v-if="solicitacao.cotacao.arquivos && solicitacao.cotacao.arquivos.length">
            <v-col cols="12">
              <span class="caption font-weight-bold">Arquivos</span>
              <div>
                <px-anexo-chip :files="solicitacao.cotacao.arquivos"
                               :download-base-path="downloadBasePath"/>
              </div>
            </v-col>
          </v-row>
          <px-card-detalhes-comprador :cotacao="solicitacao.cotacao"/>
          <div class="mt-3">
            <px-card-cotacao-produtos-list :produtos="solicitacao.cotacao.produtos"
                                           :solicitacao-id="idSolicitacao"/>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { SituacaoRespostaCotacaoProduto, SituacaoCotacao, SituacaoSolicitacaoResposta } from 'px-business-components';
import CardDetalhesComprador from './CardDetalhesComprador.vue';
import CardCotacaoProdutosList from './CardCotacaoProdutosList.vue';
import SolicitacaoService from './solicitacao-service';

const NAO_RECUSADAS = [SituacaoRespostaCotacaoProduto.keys.NAO_RESPONDIDA.key,
  SituacaoRespostaCotacaoProduto.keys.RESPONDIDA.key,
  SituacaoRespostaCotacaoProduto.keys.CANCELADA.key];

export default {
  props: {
    solicitacao: {
      type: Object,
      required: true,
    },
    idSolicitacao: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loadingRecusar: false,
    };
  },
  computed: {
    isNaoRespondida() {
      return this.solicitacao.situacaoSolicitacaoResposta
          === SituacaoSolicitacaoResposta.keys.NAO_RESPONDIDA.key;
    },
    isRespondida() {
      return this.solicitacao.situacaoSolicitacaoResposta
          === SituacaoSolicitacaoResposta.keys.RESPONDIDA.key;
    },
    isRecusada() {
      return !NAO_RECUSADAS.includes(this.solicitacao.situacaoSolicitacaoResposta);
    },
    isCotacaoAberta() {
      return this.solicitacao.cotacao.situacao
          === SituacaoCotacao.keys.ABERTA.key;
    },
    downloadBasePath() {
      return `external/solicitacao/${this.idSolicitacao}/file`;
    },
  },
  methods: {
    onClickResponder() {
      this.$router.push({ name: 'main.cotacao.resposta' });
    },
    onClickMinhaResposta() {
      this.$router.push({ name: 'main.cotacao.minharesposta' });
    },
    onRecusar(option) {
      this.loadingRecusar = true;
      SolicitacaoService.recusar(this.idSolicitacao, {
        situacaoSolicitacaoResposta: option.key,
        outroMotivo: option.motivo,
      })
        .then(() => {
          this.$toast('Solicitação recusada com sucesso', { color: 'success' });
          this.$emit('movimentar');
        })
        .finally(() => {
          this.loadingRecusar = false;
        });
    },
  },
  components: {
    pxCardCotacaoProdutosList: CardCotacaoProdutosList,
    pxCardDetalhesComprador: CardDetalhesComprador,
  },
};
</script>
