<template>
  <nav>
    <v-app-bar dark
               app
               color="primary">

      <img class="header-logo ml-2" src="../../assets/logo-branca.png"
           alt="Logo da Proxpect"/>

      <v-spacer/>
    </v-app-bar>
  </nav>
</template>

<script>
export default {};
</script>

<style scoped>
  .header-logo {
    height: 40px;
  }
</style>
