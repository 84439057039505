<template>
  <v-menu
    min-width="350px"
    v-model="opened"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-icon v-on="on">
        mdi-chevron-down
      </v-icon>
    </template>
    <v-card class="mx-auto"
            max-width="400">
      <v-card-title>
        {{ cotacaoProduto.produto.descricao }}
      </v-card-title>
      <v-card-text class="text--primary">
        <v-row>
          <v-col cols="6" v-if="cotacaoProduto.produto.codigo">
            <span class="caption font-weight-bold">Código do item</span>
            <div>
              {{ cotacaoProduto.produto.codigo }}
            </div>
          </v-col>
          <v-col cols="6" v-if="cotacaoProduto.produto.codigoFornecedor">
            <span class="caption font-weight-bold">Código fornecedor</span>
            <div>
              {{ cotacaoProduto.produto.codigoFornecedor }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="caption font-weight-bold">Especificação</span>
            <div>
              {{ cotacaoProduto.especificacaoProduto | naoInformado }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="caption font-weight-bold">Observações externas</span>
            <div>
              {{ observacaoExterna | naoInformado }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="caption font-weight-bold">Anexos</span>
            <div v-if="cotacaoProduto.arquivos.length">
              <px-anexo-chip :files="cotacaoProduto.arquivos"
                             :download-base-path="downloadBasePath"/>
            </div>
            <div v-else>
              Nenhum anexo informado
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    cotacaoProduto: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      opened: false,
    };
  },
  computed: {
    downloadBasePath() {
      return `/fornecedor/solicitacoes-respostas/${this.solicitacaoId}/file`;
    },
    observacaoExterna() {
      return this.cotacaoProduto.observacaoExterna ?? this.cotacaoProduto.produto.observacaoExterna;
    },
  },
};
</script>
