import VueCookies from 'vue-cookies';
import tokenCookiesBuilder from '@/store/token-cookies';
import { TOKEN_TYPE_FINALIZACAO } from '@/store/tokens.constants';
import {
  SET_AUTH_TOKENS, SET_AUTH_TOKENS_FIN, INITIALIZE_FROM_COOKIES, INITIALIZE_FROM_COOKIES_FIN,
} from './mutations.type';

export default {
  [SET_AUTH_TOKENS](state, { data, getters }) {
    state.tokens = data;
    VueCookies.set(tokenCookiesBuilder(getters.cotacaoId), data);
  },
  [SET_AUTH_TOKENS_FIN](state, { data, getters }) {
    state.tokenFinalizacao = data;
    VueCookies.set(tokenCookiesBuilder(getters.cotacaoId, TOKEN_TYPE_FINALIZACAO), data);
  },
  [INITIALIZE_FROM_COOKIES](state, { getters }) {
    if (state.tokens) {
      return;
    }
    state.tokens = VueCookies.get(tokenCookiesBuilder(getters.cotacaoId));
  },
  [INITIALIZE_FROM_COOKIES_FIN](state, { getters }) {
    if (state.tokenFinalizacao) {
      return;
    }
    state.tokenFinalizacao = VueCookies
      .get(tokenCookiesBuilder(getters.cotacaoId, TOKEN_TYPE_FINALIZACAO));
  },
};
