<template>
  <v-card class="mt-3 rounded-card">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="headline font-weight-bold">Negociações</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-card-text class="text--primary">
      <div>
        <px-circular-loading v-if="loading"/>
      </div>
      <div v-if="!loading && !negociacoes.length"
           align="center"
           class="headline mb-5">
        Ainda não há nenhuma negociação realizada
      </div>
      <div v-if="negociacoes.length">
        <px-negociacao-lista :negociacoes="negociacoes"
                             :cotacao-id="cotacaoId"
                             @movimentar="onMovimentar"/>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import NegociacaoService from './negociacao-service';
import NegociacaoLista from './NegociacaoLista.vue';

export default {
  props: {
    cotacaoId: String,
  },
  data() {
    return {
      negociacoes: [],
      loading: false,
    };
  },
  methods: {
    getNegociacoes() {
      this.loading = true;
      NegociacaoService.getNegociacoes(this.cotacaoId)
        .then((response) => {
          this.negociacoes = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onMovimentar() {
      this.getNegociacoes();
    },
  },
  created() {
    this.getNegociacoes();
  },
  components: {
    pxNegociacaoLista: NegociacaoLista,
  },
};
</script>
