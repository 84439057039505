import axios from 'axios';

const getFinalizacao = requestId => axios.get(`/external/pedidos/${requestId}`);
const aprovar = (requestId, compra) => axios.put(`/external/pedidos/${requestId}/aprovar`, compra);
const recusar = (requestId, compra) => axios.put(`/external/pedidos/${requestId}/recusar`, compra);
const confirmarRecebimento = (requestId, data) => axios.put(`/external/pedidos/${requestId}/receber`, data);
const receberAprovar = (requestId, data) => axios.put(`/external/pedidos/${requestId}/receber-aprovar`, data);

export default {
  getFinalizacao,
  aprovar,
  recusar,
  confirmarRecebimento,
  receberAprovar,
};
