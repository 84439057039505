import Main from '@/modules/main/Main.vue';
import Header from '@/modules/header/Header.vue';
import Resposta from '@/modules/resposta/Resposta.vue';
import MinhaResposta from '@/modules/minha-resposta/MinhaResposta.vue';
import Finalizacao from '@/modules/finalizacao/Finalizacao.vue';

import store from '@/store';
import {
  SET_AUTH_INFO,
  INITIALIZE_FROM_COOKIES_AC,
  SET_AUTH_INFO_FIN, INITIALIZE_FROM_COOKIES_AC_FIN,
} from '@/store/auth/actions.type';
import { SET_COTACAO_ID_AC } from '@/store/cotacao/actions.type';
import Cotacao from '../modules/cotacao/Cotacao.vue';

const beforeEnterAuth = (to, from, next) => {
  store.dispatch(SET_COTACAO_ID_AC, to.params.id);

  if (to.query.k) {
    store.dispatch(SET_AUTH_INFO, to.query.k);
  }

  if (!store.getters.isAuthenticated) {
    if (store.getters.tokenFromStorage) {
      store.dispatch(INITIALIZE_FROM_COOKIES_AC);
      return next();
    }
    if (to.name === 'main.cotacao') {
      return next();
    }
    return next({ name: 'main.cotacao', params: to.params });
  }

  return next();
};

const beforeEnterAuthFinalizacao = (to, from, next) => {
  store.dispatch(SET_COTACAO_ID_AC, to.params.id);

  if (to.query.k) {
    store.dispatch(SET_AUTH_INFO_FIN, to.query.k);
  }

  if (!store.getters.isAuthenticatedFinalizacao) {
    if (store.getters.tokenFromStorageFinalizacao) {
      store.dispatch(INITIALIZE_FROM_COOKIES_AC_FIN);
      return next();
    }
    if (to.name === 'main.finalizacao') {
      return next();
    }
    return next({ name: 'main.finalizacao', params: to.params });
  }

  return next();
};

export default {
  path: '/',
  name: 'main',
  redirect: { name: 'main.cotacao' },
  components: {
    default: Main,
    header: Header,
  },
  children: [
    {
      path: 'cotacao/:id',
      name: 'main.cotacao',
      components: {
        default: Cotacao,
      },
      beforeEnter: beforeEnterAuth,
    },
    {
      path: 'cotacao/:id/resposta',
      name: 'main.cotacao.resposta',
      components: {
        default: Resposta,
      },
      beforeEnter: beforeEnterAuth,
    },
    {
      path: 'cotacao/:id/minha-resposta',
      name: 'main.cotacao.minharesposta',
      components: {
        default: MinhaResposta,
      },
      beforeEnter: beforeEnterAuth,
    },
    {
      path: 'finalizacao/:id',
      name: 'main.finalizacao',
      components: {
        default: Finalizacao,
      },
      beforeEnter: beforeEnterAuthFinalizacao,
    },
  ],
};
