import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  tokens: null,
  tokenFinalizacao: null,
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
