<template>
  <div>
    <v-card outlined class="rounded-card">
      <v-subheader>Produtos</v-subheader>
      <v-divider/>
      <v-list class="rounded-card">
        <template v-for="(produto, index) in produtos">
          <v-list-item :key="produto.id">
            <v-list-item-content>
              <v-list-item-title>
                <px-span-tooltip v-if="checkCodigo(item.cotacaoProduto.produto)"
                                             top
                                             :text="labelCodigo(item.cotacaoProduto.produto)"
                                             tooltip="Código do item"
                                             />
                {{ produto.produto.descricao }}
                </v-list-item-title>
              <v-list-item-subtitle>
                {{produto.quantidade | number(getCurrencyFormat(produto.produto.casasDecimais)) }}
                {{produto.unidadeMedida.descricao}}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon>
                <v-icon>
                  mdi-paperclip
                </v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <px-detalhes-produto
                :produto="produto"
                show-obs-externa/>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="index !== (produtos.length - 1)"
                     :key="`v-div${produto.id}`"/>
        </template>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import { CasaDecimalUtils } from 'px-components';

export default {
  props: {
    produtos: Array,
  },
  computed: {
    getCurrencyFormat() {
      return item => CasaDecimalUtils.getCurrencyFormat(item);
    },
    labelCodigo() {
      return item => `${item.codigo} - `;
    },
    checkCodigo() {
      return item => item.codigo;
    },
  },
};
</script>
