<template>
  <div>
    <v-stepper class="elevation-1 rounded-card" v-model="currentStep" vertical>

      <v-stepper-step :complete="currentStep > 1" step="1">
        Produtos
      </v-stepper-step>
      <v-stepper-content step="1">
        <px-resposta-cadastro-produtos :solicitacao="solicitacao" :resposta="resposta"
                                       @next="next"/>
      </v-stepper-content>

      <v-stepper-step :complete="currentStep > 2" step="2">
        Condições
      </v-stepper-step>
      <v-stepper-content step="2">
        <px-resposta-cadastro-dados-basicos :resposta="resposta"
                                            @next="next" @previous="previous"/>
      </v-stepper-content>

      <v-stepper-step :complete="currentStep > 3" step="3">
        Frete
      </v-stepper-step>
      <v-stepper-content step="3">
        <px-resposta-cadastro-frete :resposta="resposta" @next="next" @previous="previous"/>
      </v-stepper-content>

      <v-stepper-step :complete="currentStep > 4" step="4">Confirmação</v-stepper-step>
      <v-stepper-content step="4">
        <px-resposta-cadastro-confirmacao v-if="currentStep === 4" :resposta="resposta"/>
        <v-btn color="primary"
               class="normal-btn-text rounded-tag-small"
               @click="enviarResposta"
               :loading="loading">
          Enviar
        </v-btn>
        <v-btn text class="normal-btn-text" @click="previous" :disabled="loading">
          Voltar
        </v-btn>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { SituacoesCotacao, SituacaoResposta, SituacaoRespostaCotacaoProduto } from 'px-business-components';
import RespostaCadastroDadosBasicos from './RespostaCadastroDadosBasicos.vue';
import RespostaCadastroFrete from './RespostaCadastroFrete.vue';
import RespostaCadastroProdutos from './RespostaCadastroProdutos.vue';
import RespostaCadastroConfirmacao from './RespostaCadastroConfirmacao.vue';
import RespostaService from './resposta-service';
import { defaultValue } from './resposta-utils';

export default {
  props: {
    solicitacao: Object,
  },
  data() {
    return {
      loading: false,
      currentStep: 1,
      resposta: {
        fornecedor: this.solicitacao.fornecedor,
        cotacao: this.solicitacao.cotacao,
        situacao: SituacaoResposta.keys.RESPONDIDA.key,
        formaPagamento: null,
        prazoPagamento: null,
        validade: null,
        observacao: null,
        frete: {
          tipoFrete: null,
          totalTransporte: null,
          observacaoFrete: null,
          prazoEntrega: null,
          valorMinimoFreteGratis: null,
          ofertaFreteGratis: null,
        },
        produtos: null,
      },
    };
  },
  methods: {
    next() {
      this.currentStep = this.currentStep + 1;
    },
    previous() {
      this.currentStep = this.currentStep - 1;
    },
    enviarResposta() {
      this.loading = true;
      RespostaService.save(this.buildResposta())
        .then(() => {
          this.$toast('Resposta enviada com sucesso', { color: 'success' });
          this.$router.go(-1);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    buildResposta() {
      const resposta = cloneDeep(this.resposta);
      resposta.produtos = resposta.produtos.map((produto) => {
        if (produto.situacao === SituacaoRespostaCotacaoProduto.keys.RESPONDIDA.key) {
          return produto;
        }
        return defaultValue(produto.situacao, produto.cotacaoProduto);
      });
      return resposta;
    },
  },
  created() {
    this.resposta.produtos = this.solicitacao.cotacao.produtos
      .filter(produto => SituacoesCotacao.ABERTAS.includes(produto.situacao))
      .map(produto => ({
        cotacaoProduto: produto,
        situacao: 'RESPONDIDA',
        precoUnitario: null,
        especificacaoProduto: null,
        impostos: {
          tipoAplicacaoIcms: null,
          tipoAplicacaoIpi: null,
          icms: null,
          ipi: null,
          valorIcms: 0,
          valorIpi: 0,
          total: 0,
          totalProdutos: 0,
          percentualRateio: 0,
          frete: 0,
        },
        arquivos: [],
      }));
  },
  components: {
    pxRespostaCadastroDadosBasicos: RespostaCadastroDadosBasicos,
    pxRespostaCadastroFrete: RespostaCadastroFrete,
    pxRespostaCadastroProdutos: RespostaCadastroProdutos,
    pxRespostaCadastroConfirmacao: RespostaCadastroConfirmacao,
  },
};
</script>
