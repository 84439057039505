import VueCookies from 'vue-cookies';
import { TOKEN_TYPE_FINALIZACAO } from '@/store/tokens.constants';
import tokenCookiesBuilder from '../token-cookies';

export default {
  tokens(state) {
    return state.tokens;
  },
  tokenFinalizacao(state) {
    return state.tokenFinalizacao;
  },
  token(state) {
    if (!state.tokens) {
      return null;
    }
    return state.tokens.token;
  },
  tokenHeader(state, getters) {
    const { tokens } = getters;
    if (!tokens) {
      return null;
    }
    return tokens;
  },
  tokenHeaderFinalizacao(state, getters) {
    const { tokenFinalizacao } = getters;
    if (!tokenFinalizacao) {
      return null;
    }
    return tokenFinalizacao;
  },
  requestIdHeader(state, getters) {
    const { cotacaoId } = getters;
    if (!cotacaoId) {
      return null;
    }
    return cotacaoId;
  },
  isAuthenticated(state) {
    return state.tokens;
  },
  isAuthenticatedFinalizacao(state) {
    return state.tokenFinalizacao;
  },
  tokenFromStorage(state, getters) {
    const tokens = VueCookies.get(tokenCookiesBuilder(getters.cotacaoId));
    if (!tokens) {
      return null;
    }
    return tokens;
  },
  tokenFromStorageFinalizacao(state, getters) {
    const tokens = VueCookies.get(tokenCookiesBuilder(getters.cotacaoId, TOKEN_TYPE_FINALIZACAO));
    if (!tokens) {
      return null;
    }
    return tokens;
  },
};
