<template>
  <v-timeline>
    <v-timeline-item
      v-for="negociacao in negociacoes"
      :key="negociacao.id"
      :right="isFornecedor(negociacao)"
      :left="!isFornecedor(negociacao)"
      :color="color(negociacao)"
    >
      <span slot="opposite">{{ negociacao.createdIn | dateDescription }}</span>
      <px-negociacao-solicitacao-card :negociacao="negociacao"
                                      :show-actions="isUltimaSolicitacao(negociacao)"
                                      :cotacao-id="cotacaoId"
                                      :is-fornecedor="isFornecedor(negociacao)"
                                      @movimentar="onMovimentar"/>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import { TipoNegociacao } from 'px-business-components';
import NegociacaoSolicitacaoCard from './NegociacaoSolicitacaoCard.vue';

export default {
  props: {
    negociacoes: Array,
    cotacaoId: {
      type: String,
      required: true,
    },
  },
  computed: {
    isFornecedor() {
      return negociacao => negociacao.tipo === TipoNegociacao.keys.RESPOSTA.key;
    },
    color() {
      return (negociacao) => {
        if (this.isFornecedor(negociacao)) {
          return 'primary';
        }
        return 'warning';
      };
    },
    isUltimaSolicitacao() {
      return negociacao => this.ultimaNegociacao && negociacao.id === this.ultimaNegociacao.id
          && this.ultimaNegociacao.tipo === TipoNegociacao.keys.SOLICITACAO.key;
    },
    ultimaNegociacao() {
      return this.negociacoes[0];
    },
  },
  methods: {
    onMovimentar() {
      this.$emit('movimentar');
    },
  },
  components: {
    pxNegociacaoSolicitacaoCard: NegociacaoSolicitacaoCard,
  },
};
</script>
