<template>
  <div>
    <div class="px-5 mt-3">
      <v-row dense v-if="finalizacao">
        <v-col cols="12" sm="6">
          <span class="tittle font-weight-bold">
            Nova solicitação de compra
          </span><br>
          <span class="body-2">
            Número do pedido Proxpect - {{ finalizacao.id }}
          </span><br v-if="finalizacao.codigoInterno">
          <span class="body-2" v-if="finalizacao.codigoInterno">
            Número da ordem de compra - {{ finalizacao.codigoInterno }}
          </span>
        </v-col>
        <v-col cols="12" sm="6" class="fornecedor-nome text-right body-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                {{ finalizacao.fornecedor.pessoa.nome }}
                ({{ finalizacao.fornecedor.pessoa.cnpj | cnpj}})
              </span>
            </template>
            <span>Empresa fornecedora</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </div>
    <div v-if="loading" class="text-center">
      <px-circular-loading/>
    </div>
    <div v-if="!loading && finalizacao" class="mt-3">
      <px-aviso-plataforma-fornecedor-simple v-if="!loading && finalizacao"/>
      <px-finalizacao-dados-basicos :compra-id="cotacaoId"
                                    :finalizacao="finalizacao"
                                    @movimentar="onMovimentar"/>
      <px-finalizacao-tabs :finalizacao="finalizacao"/>
      <px-finalizacao-mensagens-comprador v-if="checkMessages"
                                          :finalizacao="finalizacao"/>
      <px-finalizacao-produtos :finalizacao="finalizacao" :compra-id="cotacaoId"/>
    </div>
    <px-auth-dialog v-model="showAuth"
                    :cotacao-id="cotacaoId"
                    :is-finalizacao="true"
                    @validate="onValidate"/>
    <px-finalizacao-confirm v-model="showConfirm"
                            v-if="finalizacao"
                            :request-id="cotacaoId"
                            :compra-id="finalizacao.id"
                            :name="finalizacao.comprador.pessoa.nome"
                            @update="onUpdateConfirm"/>

<!--    <px-aviso-plataforma-fornecedor v-if="!loading && finalizacao"/>-->
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { SituacaoRespostaCotacaoProduto, SituacaoCompra } from 'px-business-components';
import AuthDialog from '@/modules/cotacao/AuthDialog.vue';
import AuthService from '@/modules/cotacao/auth-service';
import eventsMixin from '@/mixins/events-mixin';
import FinalizacaoConfirm from '@/modules/finalizacao/FinalizacaoConfirm.vue';
import FinalizacaoService from './finalizacao-service';
import FinalizacaoDadosBasicos from './FinalizacaoDadosBasicos.vue';
import FinalizacaoProdutos from './FinalizacaoProdutos.vue';
import FinalizacaoTabs from './FinalizacaoTabs.vue';
import FinalizacaoMensagensComprador from './FinalizacaoMensagensComprador.vue';

export default {
  mixins: [eventsMixin],
  data() {
    return {
      showAuth: false,
      showConfirm: false,
      loading: false,
      finalizacao: null,
    };
  },
  computed: {
    ...mapGetters({
      cotacaoId: 'cotacaoId',
      isAuthenticated: 'isAuthenticatedFinalizacao',
      tokenFinalizacao: 'tokenFinalizacao',
    }),
    isRespondida() {
      return this.solicitacao && this.solicitacao.situacaoSolicitacaoResposta
          === SituacaoRespostaCotacaoProduto.keys.RESPONDIDA.key;
    },
    labelComprador() {
      return Vue.filter('truncate')(this.finalizacao.comprador.pessoa.nome, 15);
    },
    labelOrdemCompra() {
      return `${this.finalizacao.codigoInterno} -`;
    },
    checkMessages() {
      return this.finalizacao.mensagensPersonalizadas
        && this.finalizacao.mensagensPersonalizadas.length;
    },
  },
  methods: {
    validate() {
      this.loading = true;
      AuthService.validarFinalizacao(this.cotacaoId, { token: this.tokenFinalizacao })
        .then(() => this.getFinalizacao())
        .catch(() => {
          this.showAuth = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onValidate() {
      this.loading = true;
      this.getFinalizacao()
        .finally(() => {
          this.loading = false;
        });
    },
    onMovimentar() {
      this.getFinalizacao();
    },
    getFinalizacao() {
      return FinalizacaoService.getFinalizacao(this.cotacaoId)
        .then((response) => {
          this.finalizacao = response.data;
          this.launchConfirmDialog();
          this.createEventAccess(this.eventsResource.COMPRA,
            { solicitacao: this.cotacaoId }, true);
        });
    },
    launchConfirmDialog() {
      if (this.finalizacao.status === 'COMPRA_RECEBIDA'
        || this.finalizacao.situacao !== SituacaoCompra.keys.APROVADA.key) {
        return;
      }
      this.showConfirm = true;
    },
    onUpdateConfirm() {
      this.finalizacao.status = 'COMPRA_RECEBIDA';
      this.showConfirm = false;
    },
  },
  created() {
    if (!this.isAuthenticated) {
      this.showAuth = true;
      return;
    }
    this.validate();
  },
  components: {
    pxAuthDialog: AuthDialog,
    pxFinalizacaoDadosBasicos: FinalizacaoDadosBasicos,
    pxFinalizacaoProdutos: FinalizacaoProdutos,
    pxFinalizacaoConfirm: FinalizacaoConfirm,
    pxFinalizacaoTabs: FinalizacaoTabs,
    pxFinalizacaoMensagensComprador: FinalizacaoMensagensComprador,
  },
};
</script>
<style scoped>
.tittle {
  font-size: 30px;
}
.fornecedor-nome {
  margin-top: 15px;
}
</style>
